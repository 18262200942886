import React from 'react'

import { useGetTrainingCatalogFilters } from 'hooks/catalogs/useGetTrainingCatalogFilters'

import { CatalogItemSidePanel } from './../CatalogItemSidePanel'
import { useTrainings } from './hooks/useTrainings'
import { Filters } from './Filters'
import { CategoryInfinite } from './CategoryInfinite'
import { CategorySkeleton } from './CategoryInfinite/skeleton'
import * as S from './trainings.styles'

export const Trainings = () => {
    const { selectedTraining, onChangeSelectedTraining } = useTrainings()

    const { isLoading } = useGetTrainingCatalogFilters()

    const renderContent = () => {
        if (isLoading) {
            return <CategorySkeleton />
        }

        return <CategoryInfinite />
    }

    return (
        <S.TrainingsWrapper>
            <Filters />

            <S.Content>
                <S.Title>Workplace Trainings</S.Title>

                {renderContent()}
            </S.Content>

            <CatalogItemSidePanel
                selectedCatalogItem={selectedTraining}
                onChangeSelectedCatalogItem={onChangeSelectedTraining}
            />
        </S.TrainingsWrapper>
    )
}

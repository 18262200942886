import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetPastSurveysResponse } from 'api/actions/home/pastSurveys/pastSurveysActions.types'
import { getPastSurveys } from 'api/actions/home/pastSurveys/pastSurveysActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'

export const useGetPastSurveys = () => {
    const query = useQuery<GetPastSurveysResponse, AxiosError>({
        queryKey: [Queries.getPastSurveys],
        queryFn: getPastSurveys,
        ...preventRefetchQueryOptions,
    })

    return query
}

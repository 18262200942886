import { AxiosError } from 'axios'
import { parseYearQuarter } from 'utils'

import { Queries } from 'api/queries.enum'
import { GetDiversityBreakdownByRoleResponse } from 'api/actions/home/diversityBreakdownByRole/diversityBreakdownByRoleActions.types'
import { getDiversityBreakdownByRole } from 'api/actions/home/diversityBreakdownByRole/diversityBreakdownByRoleActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const useGetDiversityBreakdownByRole = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetDiversityBreakdownByRoleResponse, AxiosError>({
        queryKey: [Queries.getDiversityBreakdownByRole, params],
        queryFn: () => getDiversityBreakdownByRole(params),
        enabled: survey !== null,
        ...preventRefetchQueryOptions,
    })

    return query
}

import React, { forwardRef, useEffect, useState } from 'react'
import { parseYearQuarter } from 'utils'
import {
    Card,
    HeatMapClassCell,
    HeatMapHeaderCellSkeleton,
    HeatMapSideCellSkeleton,
    HeatMapTableBodyCellSkeleton,
    HeatMapTableHeaderCellSkeleton,
    IntersectionalityFilterRef,
} from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { InclusionDataProps, RawInclusionMetrics, useInclusionData } from 'hooks/useInclusionData'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useExportInclusionData } from 'hooks/inclusion/export/useExportInclusionData'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import {
    GetInclusionScoresSkeletonDefaultParams,
    useGetInclusionScoresSkeleton,
} from 'hooks/inclusion/useGetInclusionScoresSkeleton'
import { useGetKpiScoreMetadata } from 'hooks/inclusion/useGetKpiScoreMetadata'
import TAXONOMIES from 'utils/taxonomies'

import { useFilters } from './../../hooks/useFilters'
import { HeatmapHeader } from './Header'
import { CellProps, HeatmapProps, SelectedCellInfo, SelectedCellProps, TableVariants } from './heatmap.types'
import { isCellCompany, isCellIndustry, isCellSideColumn } from './Panel/panel.utils'
import { isDataUnavailable } from './heatmap.utils'
import { HeatmapTables } from './Tables'
import { HeatmapTablePanel } from './Panel'
import * as S from './heatmap.styles'

const HeatmapWithoutEB = forwardRef<IntersectionalityFilterRef, HeatmapProps>(function Heatmap(
    { selectedTab, selectedTabType }: HeatmapProps,
    ref,
) {
    const [panelVisible, setPanelVisible] = useState<boolean>(false)
    const [selectedCellInfo, setSelectedCellInfo] = useState<SelectedCellInfo | undefined>()
    const { primaryIndustryBenchmark, secondaryIndustryBenchmark } = useBenchmarks()

    useGetKpiScoreMetadata()

    const { selectedFiltersPayload, isFiltersInProfilesActive } = useFilters()

    const { survey } = useSurveys()

    const isTargetGroups = selectedTab === 'target_groups'

    const filtersInProfilesDataParams = (() => {
        if (!isTargetGroups) {
            return {}
        }

        if (isFiltersInProfilesActive) {
            return {
                filters_in_profiles: isFiltersInProfilesActive,
            }
        }

        return {}
    })()

    const inclusionDataParams: InclusionDataProps = {
        ...selectedFiltersPayload,
        ...filtersInProfilesDataParams,
        year: parseYearQuarter(survey),
        key: selectedTab,
        type: selectedTabType,
    }

    const inclusionScoresSkeletonParams: GetInclusionScoresSkeletonDefaultParams = {
        key: selectedTab,
        type: selectedTabType,
    }

    if (!selectedTabType) {
        delete inclusionDataParams.type
        delete inclusionScoresSkeletonParams.type
    }

    const { data, isLoading: isQueryInclusionDataLoading } = useInclusionData(inclusionDataParams)
    const { isLoading: isQueryGetInclusionScoresSkeletonLoading } =
        useGetInclusionScoresSkeleton(inclusionScoresSkeletonParams)

    const mutationExportInclusionData = useExportInclusionData({
        ...inclusionDataParams,
        export_format: 'csv',
    })

    useEffect(() => {
        setSelectedCellInfo(undefined)
    }, [selectedTab])

    if (isQueryGetInclusionScoresSkeletonLoading) {
        return (
            <S.Heatmap>
                <Card>
                    <HeatMapHeaderCellSkeleton name="" size="medium" />
                </Card>

                <Card>
                    <S.HeatmapTables isPanelVisible={false}>
                        <S.ScrollBar>
                            <S.Table className="inclusion-heatmap inclusion">
                                <thead>
                                    <tr className="inclusion-heatmap__class-cell-container">
                                        <td />
                                        <th colSpan={13}>
                                            <HeatMapClassCell name="Inclusion Scores" />
                                        </th>
                                    </tr>

                                    <tr className="inclusion-heatmap__header-cells">
                                        <td />

                                        {new Array(12).fill('').map((_el, index) => (
                                            <td key={index}>
                                                <HeatMapTableHeaderCellSkeleton variant="text" />
                                            </td>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {new Array(24).fill('').map((_el, index) => (
                                        <tr className="inclusion-heatmap__table-row" key={index}>
                                            <td>
                                                <HeatMapSideCellSkeleton />
                                            </td>

                                            {new Array(12).fill('').map((_el, index) => (
                                                <td key={index}>
                                                    <HeatMapTableBodyCellSkeleton />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </S.Table>
                        </S.ScrollBar>
                    </S.HeatmapTables>
                </Card>
            </S.Heatmap>
        )
    }

    const downloadCSV = () => {
        return mutationExportInclusionData.mutate()
    }

    const isPanelVisible = (cellData: CellProps) => {
        const isSideCell = isCellSideColumn(cellData)

        const isSideCellAndCompanyRow = isCellCompany(cellData) && isSideCell

        const isSideCellAndBenchmarkRow =
            isCellIndustry(cellData, primaryIndustryBenchmark, secondaryIndustryBenchmark) && isSideCell

        /*
         * Panel is only visible when
         * 1. Selected cell is a side cell but not from company or benchmark row
         * 2. Selected cell is from table and has valid data and its not a side cell
         */
        return (
            !isSideCellAndCompanyRow &&
            !isSideCellAndBenchmarkRow &&
            !(isDataUnavailable(cellData.current) && !isSideCell)
        )
    }

    const getCellDataFromTable = (
        tableData: RawInclusionMetrics,
        selectedCellInfo: SelectedCellInfo,
    ): Omit<SelectedCellProps, 'tableType'> => {
        const selectedRow = tableData?.data[selectedCellInfo.row]
        const selectedKpi = selectedCellInfo.column !== null ? tableData?.columns[selectedCellInfo.column] : null

        const rowData = tableData?.data[selectedCellInfo.row]
        const selectedCellScores = selectedCellInfo.column !== null ? rowData?.scores[selectedCellInfo.column] : null

        const cellData = {
            kpi: selectedKpi,
            current: selectedCellScores?.current,
            previous: selectedCellScores?.previous,
            key: selectedKpi?.key,
            inclusionTitle: selectedRow?.title,
            inclusionSubtitle: selectedRow?.subtitle,
            inclusionSecondarySubtitle: selectedRow?.secondarySubtitle,
            profileColor: selectedRow?.profileColor,
            insight: selectedCellScores?.insight,
            isWeakness: !!selectedCellScores?.isWeakness,
            rowType: selectedRow?.type,
        }

        if (!isPanelVisible(cellData)) {
            setPanelVisible(false)
            setSelectedCellInfo(undefined)
        }

        return {
            row: selectedRow,
            column: selectedKpi,
            cell: cellData,
        }
    }

    const getSelectedCellData = (selectedCellInfo: SelectedCellInfo): SelectedCellProps => {
        if (selectedCellInfo?.table === TableVariants.Inclusion) {
            return {
                ...getCellDataFromTable(inclusionMetricsData, selectedCellInfo),
                tableType: 'InclusionMetrics',
            }
        } else if (selectedCellInfo?.table === TableVariants.Bespoke) {
            return {
                ...getCellDataFromTable(bespokeMetricsData, selectedCellInfo),
                tableType: 'bespokeMetrics',
            }
        } else {
            return {
                ...getCellDataFromTable(engagementMetricsData, selectedCellInfo),
                tableType: 'engagementMetrics',
            }
        }
    }

    const handleCellClick = (table: TableVariants, row: number, column: number | null) => {
        const selectedCellInfo = {
            table,
            row,
            column,
        }

        mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_CELL_SELECTED, getSelectedCellData(selectedCellInfo))

        setSelectedCellInfo(selectedCellInfo)

        setPanelVisible(true)
    }

    const fallbackTableData = { columns: [], data: [] }
    const inclusionMetricsData = data?.inclusionMetrics || fallbackTableData
    const bespokeMetricsData = data?.bespokeMetrics || fallbackTableData
    const engagementMetricsData = data?.engagementMetrics || fallbackTableData

    return (
        <S.Heatmap>
            <HeatmapHeader
                inclusionDataParams={inclusionDataParams}
                hasInclusionChip={!isTargetGroups}
                inclusionScoresSkeletonParams={inclusionScoresSkeletonParams}
            />

            <HeatmapTables
                inclusionMetrics={inclusionMetricsData}
                engagementMetrics={engagementMetricsData}
                bespokeMetrics={bespokeMetricsData}
                handleClick={handleCellClick}
                isPanelVisible={panelVisible}
                selectedCellInfo={selectedCellInfo}
                selectedTab={selectedTab}
                onDownloadCSV={downloadCSV}
                isQueryInclusionDataLoading={isQueryInclusionDataLoading}
                inclusionScoresSkeletonParams={inclusionScoresSkeletonParams}
                ref={ref}
            />

            {panelVisible && selectedCellInfo && (
                <HeatmapTablePanel
                    selectedIndustry={primaryIndustryBenchmark}
                    handleClose={() => setPanelVisible(false)}
                    selectedTab={selectedTab}
                    selectedTabType={selectedTabType}
                    selectedCellInfo={selectedCellInfo}
                    selectedCellData={getSelectedCellData(selectedCellInfo)}
                    setSelectedCellInfo={setSelectedCellInfo}
                    onCellClick={handleCellClick}
                    setPanelVisible={setPanelVisible}
                />
            )}
        </S.Heatmap>
    )
})

export const Heatmap = withErrorBoundary<HeatmapProps & { ref: React.Ref<IntersectionalityFilterRef> }>(
    HeatmapWithoutEB,
    {},
)

import React from 'react'
import { Button, ReloadIcon } from '@diversioteam/diversio-ds'
import pluralize from 'pluralize'

import { useGetCatalogsInfinite } from 'hooks/catalogs/useGetCalalogsInfinite'
import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'
import { CatalogItem } from 'components/Catalog/CatalogItem'
import * as S from 'components/Catalog/catalog.styles'

import { useResources } from './../hooks/useResources'

export const CategoryInfinite = () => {
    const { selectedResource, debouncedSearchValue, selectedKpis, onChangeSelectedResource, selectedResourceTypes } =
        useResources()

    const toolsFilterOption = selectedResourceTypes[0]

    const { data, isLoading, fetchNextPage, isFetchingNextPage } = useGetCatalogsInfinite({
        page_size: 12,
        search: debouncedSearchValue,
        resource_types: [toolsFilterOption.value.toString()],
        kpis: selectedKpis.map(({ value }) => value.toString()),
    })

    const resources = data?.pages

    const hasNextPage = resources && resources[resources.length - 1].next !== null

    const handleClickLoadMore = () => {
        fetchNextPage()
    }

    const renderContent = () => {
        if (resources && resources[0].results.length === 0) {
            return <S.NoResults />
        }

        return (
            <>
                <S.List>
                    {isLoading && new Array(12).fill('').map((_, index) => <CatalogItemSkeleton key={index} />)}

                    {resources?.flatMap(({ results }) =>
                        results.map((props) => (
                            <CatalogItem
                                key={props.uuid}
                                {...props}
                                isSelected={false}
                                selectedCatalogItem={selectedResource}
                                onChangeSelectedCatalogItem={onChangeSelectedResource}
                            />
                        )),
                    )}
                </S.List>

                {hasNextPage && (
                    <S.LoadMore>
                        <Button
                            rightIcon={<ReloadIcon type="line" />}
                            type="button"
                            color="primaryLight"
                            onClick={handleClickLoadMore}
                            loading={isFetchingNextPage}
                        >
                            Load More
                        </Button>
                    </S.LoadMore>
                )}
            </>
        )
    }

    return (
        <S.Category>
            <S.Header>
                <S.Title>{pluralize(toolsFilterOption.label)}</S.Title>
            </S.Header>

            {renderContent()}
        </S.Category>
    )
}

import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
    GetFreeTextsFiltersParams,
    GetFreeTextsFiltersResponse,
} from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { Queries } from 'api/queries.enum'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { getFreeTextsFilters } from 'api/actions/analyze/freeTexts/freeTextsActions'

export const useGetFreeTextsFilters = (defaultParams?: GetFreeTextsFiltersParams, options?: { enabled?: boolean }) => {
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetFreeTextsFiltersParams = { ...selectedSurveyParams, ...defaultParams }

    const query = useQuery<GetFreeTextsFiltersResponse, AxiosError>({
        queryKey: [Queries.getFreeTextsFilters, params],
        queryFn: () => getFreeTextsFilters(params),
        enabled: options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}

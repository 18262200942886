import { useContext } from 'react'

import { TourContext } from 'context/tour/tourContext/TourContext'
import { TourContextValueType } from 'context/tour/tourContext/TourContext.types'

export const useTour: () => TourContextValueType = () => {
    const context = useContext(TourContext)

    if (context === undefined) {
        throw new Error('useTour must be used within an TourContextController')
    }

    return context
}

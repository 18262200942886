import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'
import { Button, ChevronRightIcon } from '@diversioteam/diversio-ds'
import { useHistory } from 'react-router-dom'

import { AppRoute } from './../../../routing/AppRoute.enum'
import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { ForgotPasswordFields, ForgotPasswordFormData, ForgotPasswordProps } from './forgotPassword.types'
import { forgotPasswordSchema } from './forgotPassword.schema'
import Link from './../../utils/Link'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './forgotPassword.styles'

export const ForgotPassword = ({ isLoading, data, isSuccess, error, isError, onSubmit }: ForgotPasswordProps) => {
    const { t } = useTranslation()
    const history = useHistory()

    const { register, handleSubmit, errors } = useForm<ForgotPasswordFormData>({
        resolver: yupResolver(forgotPasswordSchema),
    })

    const handleSubmitCallback = useCallback(
        async (data: ForgotPasswordFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: ForgotPasswordFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.Container>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast
                    isLoading={isLoading}
                    data={data}
                    isSuccess={isSuccess}
                    error={error}
                    isError={isError}
                    translationPrefix="AUTH.FORGOT_PASSWORD"
                />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.FORGOT_PASSWORD.TITLE')}</S.Title>

                <S.Description>{t('AUTH.FORGOT_PASSWORD.HELP_TEXT')}</S.Description>

                <S.Fields>
                    <S.TextField
                        id={ForgotPasswordFields.usernameEmail}
                        inputRef={register}
                        error={Boolean(errors[ForgotPasswordFields.usernameEmail])}
                        helperText={getErrorMessage(ForgotPasswordFields.usernameEmail)}
                        name={ForgotPasswordFields.usernameEmail}
                        label={t('AUTH.LOGIN.USERNAME_EMAIL')}
                        type="text"
                        disabled={isLoading}
                        fullWidth
                    />
                </S.Fields>

                <Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.FORGOT_PASSWORD.RESET_PASSWORD')}
                </Button>

                <S.TextLink as={Link} to={{ pathname: AppRoute.Login, search: location.search }}>
                    {t('AUTH.FORGOT_PASSWORD.GO_BACK')}
                </S.TextLink>

                <Footer />
            </S.Form>
        </S.Container>
    )
}

import { AxiosError } from 'axios'
import { parseYearQuarter } from 'utils'

import { Queries } from 'api/queries.enum'
import { GetRiskBannerResponse } from 'api/actions/home/riskBanner/riskBannerActions.types'
import { getRiskBanner } from 'api/actions/home/riskBanner/riskBannerActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const useGetRiskBanner = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetRiskBannerResponse, AxiosError>({
        queryKey: [Queries.getRiskBanner, params],
        queryFn: () => getRiskBanner(params),
        enabled: survey !== null,
        ...preventRefetchQueryOptions,
    })

    return query
}

import { ActionTypes } from '../actions';
import { jwtDecode } from "jwt-decode";

import { LocalStorageKey } from 'containers/Auth/auth.types';
import { isDateMoreThanDays } from 'containers/Auth/utils';

let accessToken = localStorage.getItem("auth_access_token");
let refreshToken = localStorage.getItem("auth_refresh_token");

let userId = -1;
let decoded = null;
let mfaRequired = false;
let mfaDeviceId = null;
let username = "";
let uuid = null
let enableDebugMode = false;
let lastLogin = null
let last2faReminderTime = null
let isPasswordLogin = true

if(accessToken){
    try {
        decoded = jwtDecode(accessToken);
        userId = decoded?.user;
        username = decoded?.username;
        uuid = decoded?.uuid
        mfaRequired = decoded["2fa_required"];
        mfaDeviceId = decoded["2fa_device_id"];
        enableDebugMode = decoded["enable_debug_mode"]
        lastLogin = decoded?.last_login
        last2faReminderTime = decoded?.last_2fa_reminder_time
        isPasswordLogin = decoded?.is_password_login
    } catch (e){}
}

let isTokenExpired = !decoded?.exp || decoded.exp * 1000 < Date.now();
let isFirstTimeMfaUser = uuid && localStorage.getItem(`${LocalStorageKey.FirstTimeMfaUser}${uuid}`) === 'true'
let daysBefore2faReminder = 7

const isDefaultAuthenticated = (() => {
    if (isTokenExpired) {
        return false
    }
    // If the user still has a valid SSO login token in local storage,
    // skip checking 2FA related conditions
    if (!isPasswordLogin) {
        return !mfaRequired || !!mfaDeviceId
    }
    if (isFirstTimeMfaUser) {
        return false
    }
    if (last2faReminderTime && isDateMoreThanDays(last2faReminderTime, daysBefore2faReminder)) {
        return false
    }
    return !mfaRequired || !!mfaDeviceId
})()

if (isTokenExpired) {
    decoded = null;
    userId = null;
    mfaRequired = false;
    mfaDeviceId = null;
    accessToken = null;
    refreshToken = null;
    uuid = null;
    enableDebugMode = false;

    localStorage.removeItem("auth_access_token");
    localStorage.removeItem("auth_refresh_token");
}

const defaultState = {
    isAuthenticated: isDefaultAuthenticated,
    isDefaultAuthenticated,
    isAdmin: false,
    isRegistrationDone: false,
    accessToken: accessToken || null,
    refreshToken: refreshToken || null,
    loggedinUserId: userId,
    username: username,
    email: '',
    userId: '',
    showNps: false,
    npsLastShown: null,
    password: "",
    confirmPassword: "",
    email: decoded?.email || "",
    isLoading: !isTokenExpired && isDefaultAuthenticated,
    isChangePasswordSuccessful: false,
    isResetPasswordRequestSuccessful: null,
    isResetPasswordSuccessful: null,
    resetPasswordMessage: "",
    mfaRequired,
    mfaDeviceId,
    mfaCodeRequested: false,
    uuid: uuid || null,
    enableDebugMode: false,
    lastLogin: null,
    last2faReminderTime: null,
    isPasswordLogin: true,
    isLoadingExistingBackupCodes: false,
    isLoadingNewBackupCodes: false
};


export default (state = defaultState, action) => {
    try {

        switch (action.type) {

            case ActionTypes.DOING_REFRESH_TOKEN:
            case ActionTypes.DOING_REGISTRATION:
            case ActionTypes.DOING_SELF_SERVE_REGISTRATION:
            case ActionTypes.DOING_LOGIN:
            case ActionTypes.DOING_SIGNUP:
            case ActionTypes.DOING_INITIALIZE_SSO:
            case ActionTypes.DOING_SSO_CALLBACK:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.HANDLE_FIELD_CHANGE:
                return {
                    ...state,
                    ...action.payload
                };

            case ActionTypes.DONE_REGISTRATION:
                return {
                    ...state,
                    ...defaultState,
                    isRegistrationDone: true,
                    isLoading: false
                };

            case ActionTypes.DONE_SELF_SERVE_REGISTRATION:
                return {
                    ...state,
                    ...defaultState,
                    isRegistrationDone: true,
                    isLoading: false
                };
            case ActionTypes.ENABLE_MFA:
            case ActionTypes.DONE_LOGIN:
                {
                    const mfaRequired = action.payload.user["2fa_required"];
                    const mfaDeviceId = action.payload.user["2fa_device_id"];
                    const last2faReminderTime = action.payload.user.last_2fa_reminder_time
                    const userUUID = action.payload.user.uuid
                    const isFirstTimeMfaUser = localStorage.getItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`) === 'true'
                    daysBefore2faReminder = action.payload.daysBefore2faReminder;
                    const isAuthenticated = (() => {
                        if (mfaRequired) {
                            if (mfaDeviceId) {
                                return true
                            }
                            return false
                        }
                        if (isFirstTimeMfaUser) {
                            return false
                        }
                        if (!last2faReminderTime) {
                            return false
                        }
                        if (isDateMoreThanDays(last2faReminderTime, daysBefore2faReminder)) {
                            return false
                        }
                        return true
                    })()

                    return {
                        ...state,
                        isAuthenticated: isAuthenticated,
                        accessToken: action.payload.access_token,
                        refreshToken: action.payload.refresh_token,
                        loggedinUserId: action.payload.user.user,
                        isAdmin: action.payload.user.is_admin,
                        companyId: action.payload.user.company_id,
                        email: action.payload.user.email,
                        backupCodes: action.payload.backupCodes,
                        username: action.payload.user?.username,
                        uuid: action.payload.user?.uuid,
                        showNps: action.payload.user.show_nps,
                        npsLastShown: action.payload.user.nps_last_shown,
                        mfaRequired,
                        mfaDeviceId,
                        enableDebugMode: action.payload.user.enable_debug_mode,
                        isLoading: false,
                        lastLogin: action.payload.user.last_login,
                        last2faReminderTime: action.payload.user.last_2fa_reminder_time
                    };
                }
            case ActionTypes.DONE_REFRESH_TOKEN:
                return {
                    ...state,
                    accessToken: action.payload.access_token,
                    refreshToken: action.payload.refresh_token,
                }
            case ActionTypes.DONE_VALIDATE_VERIFICATION_CODE:
            case ActionTypes.DONE_SSO_CALLBACK:
                const mfaRequired = action.payload.user["2fa_required"];
                const mfaDeviceId = action.payload.user["2fa_device_id"];

                const isAuthenticated = (() => {
                    if (mfaRequired) {
                        if (mfaDeviceId) {
                            return true
                        }

                        return false
                    }
                        
                    return true
                })()

                const userObj = jwtDecode(action.payload.access_token);

                return {
                    ...state,
                    isAuthenticated: isAuthenticated,
                    accessToken: action.payload.access_token,
                    refreshToken: action.payload.refresh_token,
                    loggedinUserId: action.payload.user.user,
                    isAdmin: action.payload.user.is_admin,
                    companyId: action.payload.user.company_id,
                    email: action.payload.user.email,
                    backupCodes: action.payload.backupCodes,
                    username: action.payload.user?.username,
                    uuid: action.payload.user?.uuid,
                    showNps: userObj.show_nps,
                    npsLastShown: userObj.nps_last_shown,
                    mfaRequired,
                    mfaDeviceId,
                    enableDebugMode: action.payload.user.enable_debug_mode,
                    isLoading: false,
                    lastLogin: userObj.last_login,
                    last2faReminderTime: userObj.last_2fa_reminder_time,
                    isPasswordLogin: action.payload.user.is_password_login
                };
            case ActionTypes.RESET_AUTH:
                return {
                    ...defaultState,
                    isAuthenticated: false,
                    isLoading: false,
                    accessToken: null,
                    refreshToken: null,
                    mfaRequired: false,
                    mfaDeviceId: null,
                    isDefaultAuthenticated: false,
                    uuid: false
                };

            case ActionTypes.DONE_SIGNUP:
                return {
                    ...defaultState,
                    isLoading: false,
                    isAuthenticated: false,
                    accessToken: undefined,
                    refreshToken: undefined,
                    loggedinUserId: 0,
                    isAdmin: false,
                    companyId: undefined,
                    uuid: false
                };
            case ActionTypes.DONE_CHANGE_PASSWORD:
                if (action.payload.data.message.length) {
                    return {
                        ...defaultState,
                        isChangePasswordSuccessful: true
                    }
                };

            case ActionTypes.REQUEST_RESET_PASSWORD_FAIL:
                return {
                    ...defaultState,
                    isResetPasswordRequestSuccessful: false,
                    response: action.payload
                };

            case ActionTypes.DONE_REQUEST_RESET_PASSWORD:
                return {
                    ...defaultState,
                    isResetPasswordRequestSuccessful: true,
                    response: action.payload
                };

            case ActionTypes.RESET_PASSWORD_FAIL:
                return {
                    ...defaultState,
                    isResetPasswordSuccessful: false,
                    response: action.payload
                };

            case ActionTypes.DONE_RESET_PASSWORD:
                return {
                    ...defaultState,
                    isResetPasswordSuccessful: true,
                    response: action.payload
                };

            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...state,
                    isAuthenticated: false
                };

            case ActionTypes.DONE_REQUEST_VERIFICATION_CODE:
            case ActionTypes.FAILED_REQUEST_VERIFICATION_CODE:
                return {
                    ...state,
                    mfaCodeRequested: true
                };

            case ActionTypes.RESET_MFA_CODE_REQUESTED:
                return {
                    ...state,
                    mfaCodeRequested: false
                };

            case ActionTypes.DOING_FETCH_EXISTING_BACKUP_CODES:
                return {
                    ...state,
                    isLoadingExistingBackupCodes: true,
                    
                } 
            case ActionTypes.DONE_FETCH_EXISTING_BACKUP_CODES:
                return {
                    ...state,
                    isLoadingExistingBackupCodes: false,
                    backupCodes: action?.payload?.backup_codes
                    
                }
            case ActionTypes.DOING_FETCH_NEW_BACKUP_CODES:
                return {
                    ...state,
                    isLoadingNewBackupCodes: true,
                    
                }
            case ActionTypes.DONE_FETCH_NEW_BACKUP_CODES:
                return {
                    ...state,
                    isLoadingNewBackupCodes: false,
                    backupCodes: action?.payload?.backup_codes
                    
                }
            case ActionTypes.RESET_BACKUP_CODES:
                return {
                    ...state,
                    isLoadingNewBackupCodes: false,
                    isLoadingExistingBackupCodes: false,
                    backupCodes: []
                }

            case ActionTypes.FAILED_INITIALIZE_SSO:
                return {
                    ...state,
                    isLoading: false
                }

            case ActionTypes.DONE_INITIALIZE_SSO:
                return {
                    ...state,
                    authorizationUrl: action.payload?.authorization_url
                }

            case ActionTypes.FAILED_SSO_CALLBACK:
                return {
                    ...state,
                    ssoError: action.payload.detail,
                    isLoading: false
                }

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};

import styled from 'styled-components'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { Carousel as DdsCarousel, getFontStyle } from '@diversioteam/diversio-ds'

import starsImg from 'assets/images/tourBanner/stars.svg'

export const Carousel = styled(DdsCarousel)`
    .swiper-slide {
        margin-right: 0;
    }
`

export const Skeleton = styled(MuiSkeleton)`
    border-radius: 15px;
`

export const IntroCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    justify-content: center;
    min-height: 164px;
    background-color: ${({ theme }) => theme.colors.primary60};
    position: relative;
`

export const TourCard = styled(IntroCard)<{ hasBackgroundImage?: boolean }>`
    &::before,
    &::after {
        display: ${({ hasBackgroundImage }) => (hasBackgroundImage ? 'block' : 'none')};
        content: '';
        position: absolute;
        top: 50%;
        background-image: url(${starsImg});
        background-size: cover;
        background-position: center;
        width: 63px;
        height: 70px;
    }

    &::after {
        right: 65px;
        transform: translateY(-50%);
    }

    &::before {
        left: 65px;
        transform: translateY(-50%) scaleX(-1);
    }
`

export const IntroTourCardTitle = styled.h3`
    color: ${({ theme }) => theme.colors.primaryWhite};
    z-index: 10;

    ${getFontStyle('cardHeaderBold')};
`

import { KpiKey } from '@diversioteam/diversio-ds'

import { RawColumn, RawData } from 'hooks/useInclusionData'

import { InclusionParams } from './../inclusion.types'

export type InsufficientData = 'INSUFFICIENT_DATA'
export type NotAvailableData = 'N/A'

export type HeatmapProps = {
    selectedTab: InclusionParams['tab']
    selectedTabType: string
}

export type BenchmarkProps = {
    isActive: boolean
    isPrimary: boolean
    label: string
    score: number
    uuid: string
}

export type SelectedCellDetails = RawData

export type SelectedCellKpis = RawColumn

export type SelectedCellProps = {
    row: SelectedCellDetails
    column: SelectedCellKpis | null
    tableType: 'InclusionMetrics' | 'bespokeMetrics' | 'engagementMetrics'
    cell: CellProps
}

export type CellProps = {
    current?: number | NotAvailableData | InsufficientData
    previous?: number | NotAvailableData | InsufficientData
    key?: KpiKey
    inclusionTitle?: string
    inclusionSubtitle?: string
    inclusionSecondarySubtitle?: string
    profileColor?: number
    kpi?: RawColumn | null
    insight?: string
    isWeakness: boolean
    rowType?: 'dimension' | 'benchmark' | 'profile' | 'bespoke_demographic' | 'company'
}

export type SelectedCellInfo = {
    table: TableVariants
    row: number
    column: number | null
}

export type TableDataTypes = 'inclusionMetrics' | 'bespokeMetrics' | 'engagementMetrics'

export enum TableVariants {
    Inclusion = 'inclusion',
    Engagement = 'engagement',
    Bespoke = 'bespoke',
}

export enum FilterOptions {
    OverTime = 'results-over-time',
}

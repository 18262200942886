import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetEventRemindersResponse } from './eventRemindersActions.types'

export const getEventReminders = async (): Promise<GetEventRemindersResponse> => {
    const response = await diversioApiV2.get(endpoints.home.eventReminders)

    return response.data
}

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetBlogsResponse, GetToolsResponse } from './solutionsActions.types'

export const getBlogs = async (): Promise<GetBlogsResponse> => {
    const response = await diversioApiV2.get(endpoints.home.solutions.blogs)

    return response.data
}

export const getTools = async (): Promise<GetToolsResponse> => {
    const response = await diversioApiV2.get(endpoints.home.solutions.tools)

    return response.data
}

import { useContext } from 'react'

import { TrainingsContext } from './../context/TrainingsContext'

export const useTrainings = () => {
    const context = useContext(TrainingsContext)

    if (context === undefined) {
        throw new Error('TrainingsContext must be within TrainingsContextController')
    }

    return context
}

import React, { useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon, Button, ChevronRightIcon, colors } from '@diversioteam/diversio-ds'
import pluralize from 'pluralize'

import { useGetCatalogs } from 'hooks/catalogs/useGetCatalogs'
import { CatalogItem } from 'components/Catalog/CatalogItem'
import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'

import { useResources } from './../hooks/useResources'
import { CategoryProps } from './category.types'
import * as S from './category.styles'

export const Category = ({ toolsFilterOption }: CategoryProps) => {
    const [page, setPage] = useState(1)

    const {
        selectedResource,
        debouncedSearchValue,
        selectedKpis,
        onChangeSelectedResource,
        onChangeSelectedResourceTypes,
    } = useResources()

    const { data, isLoading } = useGetCatalogs({
        page,
        page_size: 4,
        search: debouncedSearchValue,
        resource_types: [toolsFilterOption.value.toString()],
        kpis: selectedKpis.map(({ value }) => value.toString()),
    })

    const handleClickPreviousPage = () => {
        setPage((prevState) => prevState - 1)
    }

    const handleClickNextPage = () => {
        setPage((prevState) => prevState + 1)
    }

    const handleChangedSelectedResourceTypes = () => {
        onChangeSelectedResourceTypes([toolsFilterOption])
    }

    const results = data?.results

    const hasPreviousPage = data?.previous !== null
    const hasNextPage = data?.next !== null

    if (results && results.length === 0) {
        return <></>
    }

    return (
        <S.Category>
            <S.Header>
                <S.Title onClick={handleChangedSelectedResourceTypes}>
                    {pluralize(toolsFilterOption.label)} {data?.count && <>({data.count})</>}{' '}
                    <ChevronRightIcon type="bold" color={colors.primary50} width={16} height={16} />
                </S.Title>

                <S.Navigation>
                    <Button
                        onClick={handleClickPreviousPage}
                        disabled={!hasPreviousPage}
                        size="small"
                        color="primaryWhite"
                        iconOnly
                    >
                        <ArrowLeftIcon type="bold" />
                    </Button>

                    <Button
                        onClick={handleClickNextPage}
                        disabled={!hasNextPage}
                        size="small"
                        color="primaryWhite"
                        iconOnly
                    >
                        <ArrowRightIcon type="bold" />
                    </Button>
                </S.Navigation>
            </S.Header>

            <S.List>
                {isLoading && new Array(4).fill('').map((_, index) => <CatalogItemSkeleton key={index} />)}

                {results?.map((props) => (
                    <CatalogItem
                        key={props.uuid}
                        {...props}
                        isSelected={false}
                        selectedCatalogItem={selectedResource}
                        onChangeSelectedCatalogItem={onChangeSelectedResource}
                    />
                ))}
            </S.List>
        </S.Category>
    )
}

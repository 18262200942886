import React from 'react'
import { Skeleton } from '@material-ui/lab'

import * as S from './../catalogItem.styles'

export const CatalogItemSkeleton = () => {
    return (
        <S.CatalogItemWrapper isSelected={false}>
            <S.Title>
                <Skeleton width={240} />
                <Skeleton width={240} />
            </S.Title>

            <S.Details>
                <Skeleton width={120} />
            </S.Details>
        </S.CatalogItemWrapper>
    )
}

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetInclusionHeatmapParams, GetInclusionHeatmapResponse } from './inclusionHeatmapActions.types'

export const getInclusionHeatmap = async (params: GetInclusionHeatmapParams): Promise<GetInclusionHeatmapResponse> => {
    const response = await diversioApiV2.get(endpoints.home.inclusionHeatmap, { params })

    return response.data
}

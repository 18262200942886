import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetInclusionInsightsParams, GetInclusionInsightsResponse } from './inclusionInsightsActions.types'

export const getInclusionInsights = async (
    params: GetInclusionInsightsParams,
): Promise<GetInclusionInsightsResponse> => {
    const response = await diversioApiV2.get(endpoints.home.inclusionInsights, { params })

    return response.data
}

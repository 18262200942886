import styled from 'styled-components'

export const Intro = styled.div`
    height: 100%;
    position: relative;

    iframe {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border: none;
    }
`

export const Button = styled.button`
    width: 158px;
    height: 44px;
    border-radius: 12px;
    padding: 0;
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    background: none;
    color: transparent;
    cursor: pointer;
`

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import mixpanel from 'mixpanel-browser'

import { Mutations } from 'api/mutations.enum'
import { GetFreeTextsParams } from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { exportFreeTexts } from 'api/actions/analyze/freeTexts/freeTextsActions'
import TAXONOMIES from 'utils/taxonomies'

export const useExportFreeTexts = (defaultParams: GetFreeTextsParams) => {
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetFreeTextsParams = { export_format: 'csv', ...selectedSurveyParams, ...defaultParams }

    const mutation = useMutation<void, AxiosError, void>({
        mutationKey: [Mutations.exportFreeTexts, params],
        mutationFn: () => {
            return exportFreeTexts(params)
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_EXPORT)
        },
    })

    return mutation
}

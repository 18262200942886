import styled from 'styled-components'

export const Navigation = styled.nav`
    display: flex;
    align-items: center;
    gap: 8px;

    .dds-button:disabled {
        opacity: 0.2;
        border-color: ${({ theme }) => theme.colors.primaryWhite};
        background-color: ${({ theme }) => theme.colors.primaryWhite};

        div[class*='-icon'] {
            background-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export { Category, Header, Title, List } from 'components/Catalog/catalog.styles'

import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    FeedbackCard,
    HarassmentRiskCard,
    HarassmentRiskCardProps,
    Button,
    CarouselRef,
} from '@diversioteam/diversio-ds'
import { Autoplay, Pagination } from 'swiper/modules'
import Lottie from 'react-lottie'

import { useGetRiskBanner } from 'hooks/useGetRiskBanner'
import { useGetProgressBanners } from 'hooks/useGetProgressBanners'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { AppRoute } from 'routing/AppRoute.enum'
import { GetRiskBannerResponse } from 'api/actions/home/riskBanner/riskBannerActions.types'
import { useTour } from 'hooks/useTour'
import * as introAnimationData from 'assets/lottie/banner/intro.json'
import * as tourAnimationData from 'assets/lottie/banner/tour.json'

import { BannerCarouselProps } from './bannerCarousel.types'
import * as S from './bannerCarousel.styles'

const BannerCarouselWithoutEB = ({ onViewDisclaimer }: BannerCarouselProps) => {
    const carouselRef = useRef<CarouselRef | null>(null)

    const [hasSlideToTourCelebration, setHasSlideToTourCelebration] = useState(false)

    const { isTourCompletedCelebrationActive, onInitTour, onFinishTourCompletedCelebration } = useTour()

    const { data: riskData, isLoading: isRiskBannerLoading } = useGetRiskBanner()
    const { data: progressData, isLoading: isProgressBannerLoading } = useGetProgressBanners()

    const hasProgressData = progressData && progressData.length > 0

    const progressElements = hasProgressData
        ? progressData.map((progress, id) => <FeedbackCard key={id + 2} title={progress.text} />)
        : []

    const isRiskDataAvailable = (data?: GetRiskBannerResponse): data is HarassmentRiskCardProps<'a'> => {
        if (!data) {
            return false
        }

        return 'title' in data
    }

    const harassmentElements = isRiskDataAvailable(riskData)
        ? [
              <HarassmentRiskCard
                  key="harassment-risk-card"
                  {...riskData}
                  onViewDisclaimer={onViewDisclaimer}
                  linkProps={{
                      component: Link,
                      to: AppRoute.SolutionGoals,
                  }}
              />,
          ]
        : []

    const handleStartTour = () => {
        setHasSlideToTourCelebration(false)

        onInitTour()
    }

    const introElement = (
        <S.IntroCard key="intro-card">
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: introAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                style={{ position: 'absolute', left: 0, right: 0 }}
            />

            <S.IntroTourCardTitle>Learn about Diversio services</S.IntroTourCardTitle>

            <Button component={Link} to={AppRoute.Intro} color="primaryWhite">
                Start Presentation
            </Button>
        </S.IntroCard>
    )

    const tourCelebrationActiveElement = [
        <S.TourCard key="celebration-tour-card">
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: tourAnimationData,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                style={{ position: 'absolute', left: 0, right: 0 }}
            />

            <S.IntroTourCardTitle>You’ve completed the tour!</S.IntroTourCardTitle>

            <Button onClick={handleStartTour} color="primaryWhite">
                Start Tour Again
            </Button>
        </S.TourCard>,
    ]

    const tourCelebrationCompletedElement = [
        <S.TourCard key="tour-card" hasBackgroundImage={true}>
            <S.IntroTourCardTitle>Tour the Diversio Platform</S.IntroTourCardTitle>

            <Button onClick={handleStartTour} color="primaryWhite">
                Start Tour
            </Button>
        </S.TourCard>,
    ]

    const tourElement = isTourCompletedCelebrationActive
        ? tourCelebrationActiveElement
        : tourCelebrationCompletedElement

    const elements = [...harassmentElements, ...progressElements, introElement, tourElement]

    const tourElementIndex = elements.findIndex((element) => element === tourElement)

    useEffect(() => {
        if (isTourCompletedCelebrationActive) {
            carouselRef.current?.onSlideTo(tourElementIndex)

            setHasSlideToTourCelebration(true)
        }
    }, [isTourCompletedCelebrationActive, tourElementIndex])

    if (isRiskBannerLoading || isProgressBannerLoading) {
        return <S.Skeleton variant="rect" height={180} data-testid="banner-carousel-skeleton" />
    }

    return (
        <S.Carousel
            ref={carouselRef}
            elements={elements}
            title={undefined}
            topNavigation={false}
            swiperProps={{
                loop: true,
                autoplay: {
                    delay: 4000,
                    pauseOnMouseEnter: true,
                },
                modules: [Autoplay, Pagination],
            }}
            onChange={(index) => {
                if (index !== tourElementIndex && hasSlideToTourCelebration) {
                    onFinishTourCompletedCelebration()
                }
            }}
            data-testid="banner-carousel"
        />
    )
}

export const BannerCarousel = withErrorBoundary(BannerCarouselWithoutEB, {})

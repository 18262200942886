import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { getNextPageParam } from 'utils/getNextPageParam'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { GetCatalogsParams, GetCatalogsResponse } from 'api/actions/catalogs/catalogsActions.types'
import { getCatalogs } from 'api/actions/catalogs/catalogsActions'

export const useGetCatalogsInfinite = (params?: GetCatalogsParams) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetCatalogsResponse, AxiosError>({
        queryKey: [Queries.getCatalogsInfinite, params],
        queryFn: ({ pageParam = queryInitialPage.current }) => getCatalogs({ ...params, page: pageParam }),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        ...preventRefetchQueryOptions,
    })

    return query
}

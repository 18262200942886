import styled from 'styled-components'

export const CatalogItemSidePanelWrapper = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    width: 544px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 86px;
    bottom: 0;
    right: 0;
    transform: ${({ isOpen }) => {
        if (isOpen) {
            return 'translateX(0)'
        }

        return 'translateX(100%)'
    }};
    transition: all 0.2s ease-in-out;
`

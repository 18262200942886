import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const MyResources = styled.section`
    margin-bottom: 64px;
`

export const Title = styled.h3`
    margin: 0 15px 18px 15px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h2Semibold')};
`

export { List } from 'containers/Catalog/Resources/Category/category.styles'

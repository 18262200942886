import { Queries } from 'api/queries.enum'
import { GetBlogsResponse } from 'api/actions/home/solutions/solutionsActions.types'
import { transformThumbnailUrl } from 'utils/transformThumbnailUrl/transformThumbnailUrl'
import { getBlogs } from 'api/actions/home/solutions/solutionsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'

export const useGetBlogs = () => {
    const query = useQuery<GetBlogsResponse, Error>({
        queryKey: [Queries.getBlogs],
        queryFn: async () => {
            const blogs: GetBlogsResponse = await getBlogs()

            const transformedBlogs = await Promise.all(
                blogs.map(async (blog) => ({
                    ...blog,
                    thumbnail: blog.thumbnail ? await transformThumbnailUrl(blog.thumbnail) : undefined,
                })),
            )

            return transformedBlogs
        },
        ...preventRefetchQueryOptions,
    })

    return query
}

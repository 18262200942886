import { useQuery } from '@tanstack/react-query'
import { loadUserMetadata } from 'sagas/network'
import _ from 'underscore'

import { GetUserMetadata, Company } from 'api/actions/userMetadata/userMetadataActions.types'
import { Queries } from 'api/queries.enum'
import { getCompanyUUID } from '../../utils/getCompanyUUID/getCompanyUUID'
import { useUserInformation } from '../useUserInformation'

export const useUserMetadata = () => {
    const userInfo = useUserInformation()
    const selectedCompanyUUID = getCompanyUUID()

    const transformData = (data: GetUserMetadata): GetUserMetadata & { currentCompany?: Company } => {
        return {
            ...data,
            currentCompany: _.findWhere(data.companies, { uuid: selectedCompanyUUID }),
        }
    }

    const query = useQuery<GetUserMetadata, Error, GetUserMetadata & { currentCompany?: Company }>({
        queryKey: [Queries.getUserMetadata],
        queryFn: () => loadUserMetadata(userInfo.userUUID),
        select: transformData,
        enabled: userInfo.isAuthenticated,
        keepPreviousData: true,
    })

    return query
}

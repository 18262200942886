import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/mutations.enum'
import { deleteJobFile } from 'api/actions/lumi/lumiActions'

export const useDeleteJobFile = () => {
    const mutation = useMutation<void, AxiosError, { jobId: string; jobFileId: string }>({
        mutationFn: ({ jobId, jobFileId }) => deleteJobFile(jobId, jobFileId),
        mutationKey: [Mutations.deleteJobFile],
        onSuccess: () => {},
    })

    return mutation
}

import React, { useEffect } from "react";
import { Router, Switch } from "react-router-dom";
import history from "./history";
import PrivateRoute from "./containers/PrivateRoute";
import AdminRoute from "./containers/AdminRoute";
import Home from "./containers/Home";
import Analyze from "./containers/Analyze";
import Bespoke from "./containers/Bespoke";
import UploadCSV from "./containers/UploadCSV";
import Account from "./containers/Account";
import Communications from "./containers/Communications";
import Oauth from "./components/Oauth";
import SurveyContainer from "./containers/Survey";
import ILNHome from "./containers/ILNDashboard/ILNHome";
import ILNImpact from "./containers/ILNDashboard/ILNImpact";
import queryString from 'query-string'
import _ from "lodash";
import { HomeV2 } from "components/HomeV2";
import { AnalyzeV2 } from "components/AnalyzeV2";
import { Goals } from "containers/Goals";
import { ResourcesContainer } from "containers/Catalog/Resources";
import { TrainingsContainer } from "containers/Catalog/Trainings";
import Programs from "containers/Programs";
import Recommended from "containers/Recommended";
import MySolutions from "containers/MySolutions";
import MySolutionsArchive from "containers/MySolutionsArchive";
import { useUserMetadata } from "hooks/metadata/useUserMetadata";
import { getCompanyRelativePathName } from "sagas/util";
import { useUserInformation } from "hooks/useUserInformation";
import { generateCompanyURI } from "sagas/helpers/generateCompanyURI";
import { useVersion } from "hooks/useVersion";
import { LumiContainer } from "components/Lumi";
// Map from Permission tag to appropriate URL (these match exactly what BE company_information api returns)

export const solutionUrls = {
  solution_goals: "goals",
  solution_recommended: "recommended",
  solution_programs: "programs",
  solution_catalog: "resources",
  solution_trainings: "trainings",
  solution_my_solutions: "my-solutions",
  solution_my_solutions_archive: "my-solutions/archive"
}

export const analyzeUrls = {
  analyze2: "analyze2",
  analyze_diversity: "analyze2/diversity",
  analyze_inclusion: "analyze2/inclusion"
}

export const urlMap = { 
  home: "home",
  home2: "home2",
  analyze: "analyze",
  analyze2: "analyze2",
  bespoke: "bespoke",
  diversity_pe: "diversity_pe",
  diversity_vc: "diversity_vc",
  voyager: "communications",
  survey: "survey",
  iln_dashboard_home: "iln-dashboard/home",
  iln_dashboard_impact: "iln-dashboard/impact",
  choose_dashboard: "choose-dashboard",
  lumi: "lumi",
  ...solutionUrls,
  ...analyzeUrls
};

export const urlToPermissionsMap = {
  [urlMap.home]: "home",
  [urlMap.home2]: "home",
  [urlMap.analyze]: "analyze",
  [`${urlMap.analyze}/diversity`]: "analyze",
  [`${urlMap.analyze}/inclusion`]: "analyze",
  [urlMap.bespoke]: "bespoke",
  [`${urlMap.bespoke}/demographics`]: "bespoke",
  [`${urlMap.bespoke}/engagement`]: "bespoke",
  [urlMap.voyager]: "voyager",
  [urlMap.survey]: "survey",
  [`${urlMap.survey}/init`]: "survey",
  [`${urlMap.survey}/init`]: "survey",
  [`${urlMap.survey}/create`]: "survey",
  [`${urlMap.survey}/draft`]: "survey",
  [`${urlMap.survey}/review`]: "survey",
  [`${urlMap.survey}/live`]: "survey",
  [`${urlMap.survey}/processing`]: "survey",
  [`${urlMap.survey}/setup`]: "survey",
  [`${urlMap.survey}/requires-updates`]: "survey",
  [`${urlMap.survey}/launch`]: "survey",
  [`${urlMap.survey}/approved`]: "survey",
  [urlMap.iln_dashboard_home]: "ilnDashboardHome",
  [urlMap.iln_dashboard_impact]: "ilnDashboardImpact",
  [urlMap.solution_goals]: "solutionGoals",
  [urlMap.solution_recommended]: "solutionRecommended",
  [urlMap.solution_programs]: "solutionPrograms",
  [urlMap.solution_catalog]: "solutionCatalog",
  [urlMap.solution_trainings]: "solutionTrainings",
  [urlMap.solution_my_solutions]: "solutionMySolutions",
  [urlMap.solution_my_solutions_archive]: "solutionMySolutions",
  [urlMap.analyze_diversity]: "analyze2/diversity",
  [urlMap.analyze_inclusion]: "analyze2/inclusion",
  [urlMap.lumi]: "lumiJobAds"
}

const noPermissionsUrls = ['account', 'account/profile', 'account/password', 'account/mfa', 'account/tokens']

function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const DashboardRoutes = ({ permissions: defPermissions, url, companyInformation }) => {
  const permissions = {
    ...defPermissions
  }

  const [redirection, setRedirection] = React.useState("");
  const [rerender, setRerender] = React.useState(false);

  const { data: userMetadata, isLoading: isLoadingUserMetadata } = useUserMetadata()
  const isHomeOrAnalyzeV2 = ["home2", "analyze2", "analyze2/diversity", "analyze2/inclusion", "analyze2/equity"].filter(permittedPath => {
    return url.includes(permittedPath)
  }).length
  
  const { enableDebugMode } = useUserInformation()

  const prevCompany = usePrevious(companyInformation);
  const versionsContext = useVersion()
  const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2

  useEffect(()=>{
      setRerender(!rerender);
  }, [isV2Enabled]);
  
  const checkForCurrentPagePermissions = () => {
    const { pathname } = history.location;
    const page = getCompanyRelativePathName(pathname).slice(1);
    const permissionToCheck = urlToPermissionsMap[page];

    if (noPermissionsUrls.includes(page)) {
      return true
    }
    
    return permissions[permissionToCheck];
  };

  const getFirstPermittedPageUrl = () => {
    for (const page in urlMap) {
      if (permissions[page]) {
        return urlMap[page];
      }
    }
  };

  // Check if the current url is permitted, otherwise redirect to first permitted page
  useEffect(() => {
    const { to } = queryString.parse(location.search)
    if (to) {
      return setRedirection(to)
    }
    if (url !== generateCompanyURI("choose-dashboard") && !isHomeOrAnalyzeV2) {
      if (!checkForCurrentPagePermissions()) {
        setRedirection(getFirstPermittedPageUrl());
      }
    }
  }, [companyInformation, history.location.pathname]);

  // Update the page when the redirection var changes.
  useEffect(() => {
    if (redirection && location.pathname !== generateCompanyURI(redirection)) {
      history.push(generateCompanyURI(redirection));
    }
  }, [redirection]);

  // If the user tries to navigate to "", navigate them to home
  useEffect(() => {
    if (history.location.pathname === generateCompanyURI("")) {
      setRedirection(isV2Enabled ? generateCompanyURI("home2") : generateCompanyURI("home"));
    }
  }, [history.location.pathname]);

  // Logic for when the dashboard is switched, redirect all users to ""
  useEffect(() => {
    if (
      prevCompany !== undefined &&
      prevCompany?.name !== companyInformation?.name
    ) {
      setRedirection(generateCompanyURI(""));
    }
  }, [companyInformation?.name]);

  if (isLoadingUserMetadata || !userMetadata || !versionsContext?.version) {
    return null
  }

  const isV2OnlyVisible = isV2Enabled && !enableDebugMode

  return (
    // The permissions.solutions_goals ternary is used for backwards compatibility with our old designs on the home and analyze page
    // and the new solution pages
    <div
      className="content-container"
    >
        <Switch>
          <PrivateRoute
            key="home"
            viewTrackingName="Home"
            exact
            path={generateCompanyURI("")}
            isActive={permissions.home}
            permissions={permissions}
            component={Home}
          />
          <PrivateRoute
            key="home"
            viewTrackingName="Home V1"
            exact
            path={generateCompanyURI(urlMap.home)}
            isActive={permissions.home}
            permissions={permissions}
            component={Home}
          />
          <PrivateRoute
            key="home2"
            viewTrackingName="Home V2"
            exact
            path={generateCompanyURI(urlMap.home2)}
            isActive={permissions.home}
            permissions={permissions}
            component={HomeV2}
          />
          <PrivateRoute
            key="analyze"
            path={generateCompanyURI(urlMap.analyze)}
            isActive={permissions.analyze}
            permissions={permissions}
            component={Analyze}
          />
          <PrivateRoute
            path={generateCompanyURI("analyze2")}
            permissions={permissions}
            isActive={isV2Enabled}
            component={AnalyzeV2}
          />
          <PrivateRoute
            key="bespoke"
            path={generateCompanyURI(urlMap.bespoke)}
            isActive={permissions.bespoke}
            permissions={permissions}
            component={Bespoke}
          />
          <PrivateRoute
            key={"voyager"}
            exact
            path={generateCompanyURI(urlMap.voyager)}
            isActive={permissions.voyager}
            permissions={permissions}
            component={Communications}
          />
          <PrivateRoute
            path={generateCompanyURI("oauth")}
            permissions={permissions}
            component={Oauth}
          />
          <PrivateRoute
            path={generateCompanyURI("account")}
            permissions={permissions}
            component={Account}
          />
          <PrivateRoute
            path={generateCompanyURI("survey")}
            permissions={permissions}
            isActive
            component={SurveyContainer}
          />
          <AdminRoute path={generateCompanyURI("admin/upload")} component={UploadCSV} />
          <PrivateRoute
            exact
            path={generateCompanyURI("iln-dashboard/home")}
            permissions={permissions}
            component={ILNHome}
          />
          <PrivateRoute
            exact
            path={generateCompanyURI("iln-dashboard/impact")}
            permissions={permissions}
            component={ILNImpact}
          />
          <PrivateRoute
            viewTrackingName="Explore Programs: Get Started V2"
            exact
            path={generateCompanyURI("goals")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={Goals}
          />
          <PrivateRoute
            viewTrackingName="My Solutions V2"
            exact
            path={generateCompanyURI("my-solutions")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={MySolutions}
          />
          <PrivateRoute
            viewTrackingName="My solutions archive"
            exact
            path={generateCompanyURI("my-solutions/archive")}
            permissions={permissions}
            component={MySolutionsArchive}
          />
          <PrivateRoute
            viewTrackingName="Explore Programs: Recommended V2"
            exact
            path={generateCompanyURI("recommended")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={Recommended}
            metadata={ userMetadata }
          />
          <PrivateRoute
            viewTrackingName="Explore Programs: All Programs V2"
            exact
            path={generateCompanyURI("programs")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={Programs}
          />
          <PrivateRoute
            key="Resources"
            viewTrackingName="Resources V2"
            exact
            path={generateCompanyURI("resources")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={ResourcesContainer}
          />
          <PrivateRoute
            key="Trainings"
            viewTrackingName="Trainings V2"
            exact
            path={generateCompanyURI("trainings")}
            isActive={isV2Enabled}
            permissions={permissions}
            component={TrainingsContainer}
          />
          <PrivateRoute
            viewTrackingName="Lumi"
            exact
            path={generateCompanyURI("lumi")}
            permissions={permissions}
            component={LumiContainer}
          />
        </Switch>
    </div>
  );
};

export default DashboardRoutes;

import { AxiosError } from 'axios'
import { parseYearQuarter } from 'utils'

import { Queries } from 'api/queries.enum'
import { GetDiversityBreakdownResponse } from 'api/actions/home/diversityBreakdown/diversityBreakdownActions.types'
import { getDiversityBreakdown } from 'api/actions/home/diversityBreakdown/diversityBreakdownActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const useGetDiversityBreakdown = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    return useQuery<GetDiversityBreakdownResponse, AxiosError>({
        queryKey: [Queries.getDiversityBreakdown, params],
        queryFn: () => getDiversityBreakdown(params),
        enabled: survey !== null,
        ...preventRefetchQueryOptions,
    })
}

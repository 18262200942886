import {
    BasicMetadata,
    Catalog,
    CatalogMetadataKey,
    CatalogType,
    TrainingMetadata,
} from 'api/actions/catalogs/catalogsActions.types'

const METADATA_MAP: Record<CatalogType, CatalogMetadataKey> = {
    Report: CatalogMetadataKey.ReportMetadata,
    Resource: CatalogMetadataKey.ResourceMetadata,
    Tool: CatalogMetadataKey.ToolMetadata,
    Training: CatalogMetadataKey.TrainingMetadata,
}

export const getCatalogMetadataKey = (catalog: Catalog): CatalogMetadataKey => {
    const metadataKey = METADATA_MAP[catalog.catalogType]

    return metadataKey
}

export const getCatalogMetadata = (catalog: Catalog) => {
    const metadataKey = getCatalogMetadataKey(catalog)

    if (!metadataKey) {
        return null
    }

    if (metadataKey === CatalogMetadataKey.TrainingMetadata) {
        return catalog[metadataKey]
    }

    return catalog[metadataKey]
}

export const isTrainingMetadata = (metadata: TrainingMetadata | BasicMetadata | null): metadata is TrainingMetadata => {
    return metadata !== null && !!(metadata as TrainingMetadata).trainingLevel
}

const importAll = (requireContext: __WebpackModuleApi.RequireContext) => {
    const images: Record<string, string> = {}

    requireContext.keys().forEach((key: string) => {
        const normalizedKey = key
            .replace('./', '')
            .replace(/\.(png|jpe?g|svg)$/i, '')
            .toLowerCase()

        images[normalizedKey] = requireContext(key)
    })

    return images
}

export const catalogImages = importAll(require.context('./../../assets/images/catalog', false, /\.(png|jpe?g|svg)$/))

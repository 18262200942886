import { useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { getNextPageParam } from 'utils/getNextPageParam'
import { getInclusionPanelAllTrainings } from 'api/actions/inclusion/inclusionActions'
import {
    GetInclusionPanelAllTrainingsParams,
    GetInclusionPanelAllTrainingsResponse,
} from 'api/actions/inclusion/inclusionActions.types'

export const useGetInclusionPanelAllTrainings = (
    params?: GetInclusionPanelAllTrainingsParams,
    options?: { enabled?: boolean },
) => {
    const queryInitialPage = useRef(params?.page || 1)

    const query = useInfiniteQuery<GetInclusionPanelAllTrainingsResponse, AxiosError>({
        queryKey: [Queries.getInclusionPanelAllTrainings, params],
        queryFn: ({ pageParam = queryInitialPage.current }) =>
            getInclusionPanelAllTrainings({ ...params, page: pageParam }),
        getNextPageParam: ({ next }) => getNextPageParam(next),
        enabled: options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}

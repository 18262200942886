import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    GetCatalogFiltersResponse,
    GetCatalogsParams,
    GetCatalogsResponse,
    GetMyCatalogsResponse,
    SaveCatalogResponse,
} from './catalogsActions.types'

export const getCatalogFilters = async (): Promise<GetCatalogFiltersResponse> => {
    const response = await diversioApiV2.get(endpoints.catalogs.filters)

    return response.data
}

export const getCatalogs = async (params?: GetCatalogsParams): Promise<GetCatalogsResponse> => {
    const response = await diversioApiV2.get(endpoints.catalogs.list, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getMyCatalogs = async (): Promise<GetMyCatalogsResponse> => {
    const response = await diversioApiV2.get(endpoints.catalogs.myCatalogs)

    return response.data
}

export const saveCatalog = async (id: string): Promise<SaveCatalogResponse> => {
    const response = await diversioApiV2.post(endpoints.catalogs.saveCatalog(id))

    return response.data
}

export const deleteCatalogFromSaved = async (id: string): Promise<void> => {
    await diversioApiV2.delete(endpoints.catalogs.deleteCatalog(id))
}

export const getTrainingCatalogFilters = async (): Promise<GetCatalogFiltersResponse> => {
    const response = await diversioApiV2.get(endpoints.trainingsCatalogs.filters)

    return response.data
}

export const getTrainingsCatalogs = async (params?: GetCatalogsParams): Promise<GetCatalogsResponse> => {
    const response = await diversioApiV2.get(endpoints.trainingsCatalogs.list, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

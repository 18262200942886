import { useContext, useEffect } from 'react'
import { getActiveCompany } from 'utils'

import { VersionContext } from 'context/versions/versionsContext/VersionsContext.types'
import { VersionsContext } from 'context/versions/versionsContext/VersionsContext'
import { FeatureFlag } from 'types/featureFlag.types'

import { useUserMetadata } from './metadata/useUserMetadata'

/*                                                                  ┌─────────────────────┐                                                                                                                         
*                                                                   │   User Logs In      │                                                                                                                         
*                                                                   └──────────┬──────────┘                                                                                                                         
*                                                                              │                                                                                                                                    
*                                                                              │                                                                                                                                    
*                                                                              │                                                                                                                                    
*                                                                   ┌──────────▼────────────┐                           Yes                                                                                         
*                                                 No                │User Preference Exists ├────────┬──────────────────────────────────────────────────────┐                                                       
*                                           ┌───────────────────────┴───────────────────────┘        │                                                      │                                                       
*                                           │                                                        │                                                      │                                                       
*                                           │                                                        │Yes                                                   │                                                       
*                                           │                                                        │                                                      │                                                       
*                                           │                                                        │                                                      │                                                       
*                           ┌───────────────▼────────────┐                                   ┌───────▼────────┐                                       ┌─────▼──────────┐                                            
*           ┌───Yes─────────┤Dashboard Supports V1 and V2├─┐                                 │Preference is V2│                                       │Preference is V1│                                            
*           │               └────────────────────────────┘ │                                 └───────┬────────┘                                       └──────────┬─────┘                                            
*           │                                              │No                                       │                                                           │                                                  
*           │                                              │                                         │                                                           │                                                  
*           │                                              │                                         │                                                           │                                                  
*           │                                              │                                         │                                                           │                                                  
┌─────────▼────────────────┐           ┌─────────────────▼────────────────┐             ┌──────────▼──────────┐                                        ┌───────▼───────────────┐                                  
│Set Preference to V2      │           │Proceed Based On Dashboard Support│             │Dashboard Supports V2├──────────┐                             │ Access to New Company ├────────────────────┐             
│Proceed with V2 Dashboard │           │                                  │             └──┬──────────────────┘          │                             │    (no v1 support)?   │                    │             
└──────────────────────────┘           └──────────────────────────────────┘                │                            No                             └──────────┬────────────┘                   No             
*                                                                                           Yes                           │                                        │                                 │             
*                                                                                           │                             │                                       Yes                                │             
*                                                                                           │                             │                                        │                                 │             
*                                                                              ┌────────────▼────────────┐          ┌─────▼───────────────────┐         ┌──────────▼────────┐           ┌────────────▼────────────┐
*                                                                              │Proceed with V2 Dashboard│          │Proceed with V1 Dashboard│         │ Show Company in V1│           │Proceed with V2 Dashboard│
*                                                                              └─────────────────────────┘          └─────────────────────────┘         └───────────────────┘           └─────────────────────────┘
*/

export const useVersion = (): VersionContext => {
    const context = useContext(VersionsContext)

    if (context === undefined) {
        throw new Error('useVersion must be used within an VersionContextController')
    }

    const { data: userMetadata } = useUserMetadata()

    const companyInformation = getActiveCompany(userMetadata?.companies)

    const userVersionPreferenceFlags = userMetadata?.featureFlags?.filter(
        (flag: FeatureFlag) => flag.name === 'home-analyze-v1-v2',
    )?.[0]

    const v1FeatureFlag = companyInformation?.featureFlags?.filter(
        (flag: FeatureFlag) => flag.name === 'home-analyze-v1',
    )?.[0]

    const v2FeatureFlag = companyInformation?.featureFlags?.filter(
        (flag: FeatureFlag) => flag.name === 'home-analyze-v2',
    )?.[0]

    const isV1Enabled = v1FeatureFlag?.enabled || false
    const isV2Enabled = v2FeatureFlag?.enabled || false

    useEffect(() => {
        if (!isV1Enabled) {
            return
        }

        if (isV1Enabled && isV2Enabled) {
            context.setIsVersionSwitchable(true)
        } else {
            context.setIsVersionSwitchable(false)
        }

        // User Specific Logic
        if (userVersionPreferenceFlags?.value) {
            if (userVersionPreferenceFlags.value === v2FeatureFlag.uuid) {
                if (v2FeatureFlag.enabled) context.setVersion(context.AVAILABLE_VERSIONS.v2)
                else context.setVersion(context.AVAILABLE_VERSIONS.v1)
            } else if (userVersionPreferenceFlags.value === v1FeatureFlag.uuid) {
                if (v1FeatureFlag.enabled) context.setVersion(context.AVAILABLE_VERSIONS.v1)
                else context.setVersion(context.AVAILABLE_VERSIONS.v2)
            }
            return
        }

        // Company Specific Logic
        if (v2FeatureFlag?.enabled) {
            context.setVersion(context.AVAILABLE_VERSIONS.v2)
        } else {
            context.setVersion(context.AVAILABLE_VERSIONS.v1)
        }
    }, [isV1Enabled, isV2Enabled, userVersionPreferenceFlags?.value])

    return context
}

import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { GetCatalogsParams, GetCatalogsResponse } from 'api/actions/catalogs/catalogsActions.types'
import { getCatalogs } from 'api/actions/catalogs/catalogsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetCatalogs = (params?: GetCatalogsParams) => {
    const query = useQuery<GetCatalogsResponse, AxiosError>({
        queryKey: [Queries.getCatalogs, params],
        queryFn: () => getCatalogs(params),
        ...preventRefetchQueryOptions,
    })

    return query
}

import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { RedirectAuthenticatedUser } from 'containers/Auth/RedirectAuthenticatedUser'
import { useCompleteIntro } from 'hooks/metadata/useCompleteIntro'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { Queries } from 'api/queries.enum'

import * as S from './intro.styles'

export const Intro = () => {
    const [hasCompletedIntro, setHasCompletedIntro] = useState(false)

    const queryClient = useQueryClient()

    const { mutate } = useCompleteIntro()

    const { data } = useUserMetadata()

    const userUUID = data?.uuid || ''

    if (hasCompletedIntro) {
        return <RedirectAuthenticatedUser />
    }

    const handleCompleteIntro = () => {
        mutate(
            { userUUID },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([Queries.getUserMetadata])

                    setHasCompletedIntro(true)
                },
            },
        )
    }

    return (
        <S.Intro>
            <iframe src="https://introsplash.framer.website/" title="Diversio Intro" />

            <S.Button onClick={handleCompleteIntro}>My Dashboard</S.Button>
        </S.Intro>
    )
}

import React from 'react'

import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'

import * as S from './../categoryInfinite.styles'

export const CategorySkeleton = () => {
    return (
        <S.CategoryWrapper>
            <S.List>
                {new Array(12).fill('').map((_, index) => (
                    <CatalogItemSkeleton key={index} />
                ))}
            </S.List>
        </S.CategoryWrapper>
    )
}

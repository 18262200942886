import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import {
    GetInclusionPanelTrainingsParams,
    GetInclusionPanelTrainingsResponse,
} from 'api/actions/inclusion/inclusionActions.types'
import { getInclusionPanelTrainings } from 'api/actions/inclusion/inclusionActions'

export const useGetInclusionPanelTrainings = (
    params?: GetInclusionPanelTrainingsParams,
    options?: { enabled?: boolean },
) => {
    const query = useQuery<GetInclusionPanelTrainingsResponse, AxiosError>({
        queryKey: [Queries.getInclusionPanelTrainings, params],
        queryFn: () => getInclusionPanelTrainings(params),
        enabled: options?.enabled,
        ...preventRefetchQueryOptions,
    })

    return query
}

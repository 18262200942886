import styled from 'styled-components'
import { getFontStyle, makeLineClamp } from '@diversioteam/diversio-ds'

export const ChipsList = styled.ul`
    margin: 8px 0 0;
    padding: 0;
    list-style-type: none;
`

export const ChipsListItem = styled.li`
    padding: 12px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray1};

    .dds-chip__label {
        word-break: break-all;

        ${makeLineClamp(1)};
    }
`

export const ChipDescription = styled.span`
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('captionRegularM')}
`

export const Objectives = styled.div`
    margin-top: 32px;
`

export const ObjectivesTitle = styled.h3`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}
`

import styled from 'styled-components'
import { Button as DdsButton, getFontStyle } from '@diversioteam/diversio-ds'

import { catalogImages } from 'components/Catalog/catalog.helpers'

export const CatalogItemDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 1px;

    .dds-button {
        margin-right: auto;
        overflow: hidden;
    }
`

export const Container = styled.div`
    overflow: auto;
    padding: 48px 48px 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    scrollbar-gutter: stable both-edges;
`

export const CloseButton = styled(DdsButton)`
    &.dds-button {
        position: absolute;
        left: -48px;
        top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.primaryWhite};
        box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
    }
`

export const ThumbnailWrapper = styled.div`
    height: 231px;
    min-height: 231px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
`

export const Thumbnail = styled.div<{ categoryKey?: string }>`
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary10};
    background-image: ${({ categoryKey }) => {
        if (!categoryKey) {
            return
        }

        return catalogImages[categoryKey] ? `url(${catalogImages[categoryKey]})` : 'none'
    }};
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 12px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const SaveButton = styled(DdsButton)`
    &.dds-button {
        position: absolute;
        bottom: -17px;
        right: -15px;
    }
`

export const Title = styled.h2`
    margin: 0 0 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p01Semibold')}
`

export const Description = styled.p`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('subheaderRegular')}
`

export const Objectives = styled.div`
    margin-bottom: 20px;
`

export const ObjectivesTitle = styled.h3`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('cardHeaderBold')}
`

export const Details = styled.div`
    margin-top: 64px;

    &:last-of-type {
        margin-bottom: 24px;
    }
`

export const DetailsTitle = styled.h3`
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}
`

export const DetailsList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px 24px;
`

export const DetailsListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const Buttons = styled.div`
    display: flex;
    gap: 12px;
    padding: 24px 48px 48px;

    .dds-button {
        width: 100%;
    }
`

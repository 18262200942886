import React, { ChangeEvent } from 'react'
import { FormControlLabel } from '@material-ui/core'
import { Checkbox, SearchBox, SelectableChip } from '@diversioteam/diversio-ds'

import { useGetCatalogFilters } from 'hooks/catalogs/useGetCatalogFilters'
import { CatalogFilterOption } from 'api/actions/catalogs/catalogsActions.types'
import { useGetCatalogs } from 'hooks/catalogs/useGetCatalogs'

import { useResources } from './../hooks/useResources'
import { FiltersSkeleton } from './skeleton'
import * as S from './filters.styles'

export const Filters = () => {
    const {
        searchValue,
        onChangeSearchValue,
        selectedKpis,
        onChangeSelectedKpis,
        selectedResourceTypes,
        onChangeSelectedResourceTypes,
    } = useResources()

    const queryGetCatalogFilters = useGetCatalogFilters()

    const queryGetCatalogs = useGetCatalogs()

    if (queryGetCatalogFilters.isLoading) {
        return <FiltersSkeleton />
    }

    if (!queryGetCatalogFilters.data) {
        return null
    }

    const resourceTypesFilters = queryGetCatalogFilters.data.filters.find(({ name }) => name === 'resource_types')
    const kpisFilters = queryGetCatalogFilters.data.filters.find(({ name }) => name === 'kpis')

    const hasKpisSelected = selectedKpis.length > 0
    const hasResourceTypesSelected = selectedResourceTypes.length > 0

    const allCatalogsCount = queryGetCatalogs.data?.count

    const handleChangeResourceType = (event: ChangeEvent<HTMLInputElement>, filterOption?: CatalogFilterOption) => {
        const { value, checked } = event.target

        if (!resourceTypesFilters) return

        if (value === 'ALL') {
            return onChangeSelectedResourceTypes([])
        }

        if (!filterOption) return

        const isSelectAllState = selectedResourceTypes.length === resourceTypesFilters.options.length - 1

        if (checked || !hasResourceTypesSelected) {
            const updatedResourceTypes = isSelectAllState ? [] : [...selectedResourceTypes, filterOption]

            onChangeSelectedResourceTypes(updatedResourceTypes)
        } else {
            const updatedResourceTypes = selectedResourceTypes.filter(
                (selectedResourceType) => selectedResourceType.value.toString() !== value,
            )

            onChangeSelectedResourceTypes(updatedResourceTypes)
        }
    }

    const handleChangeKpi = (event: ChangeEvent<HTMLInputElement>, filterOption?: CatalogFilterOption) => {
        const { name, checked } = event.target

        if (!kpisFilters) return

        if (name === 'ALL') {
            return onChangeSelectedKpis([])
        }

        if (!filterOption) return

        const isSelectAllState = selectedKpis.length === kpisFilters.options.length - 1

        if (checked) {
            const updatedKpis = isSelectAllState ? [] : [...selectedKpis, filterOption]

            onChangeSelectedKpis(updatedKpis)
        } else {
            const updatedKpis = selectedKpis.filter((selectedKpi) => selectedKpi.value.toString() !== name)

            onChangeSelectedKpis(updatedKpis)
        }
    }

    return (
        <S.FiltersWrapper>
            <S.Filters>
                <S.FiltersGroup>
                    <SearchBox
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        defaultExpanded
                        keepExpanded
                        placeholder="Search"
                    />
                </S.FiltersGroup>

                {resourceTypesFilters && (
                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>{resourceTypesFilters.label}</S.FiltersGroupTitle>

                        <S.FormGroup as="ul">
                            <S.FormGroupItem>
                                <SelectableChip
                                    variant="outlined"
                                    size="small"
                                    focused={!hasResourceTypesSelected}
                                    selected={!hasResourceTypesSelected}
                                    name="ALL"
                                    label={`All${allCatalogsCount ? ` (${allCatalogsCount})` : ''}`}
                                    value="ALL"
                                    onChange={(event) => handleChangeResourceType(event)}
                                />
                            </S.FormGroupItem>

                            {resourceTypesFilters.options.map((filterOption) => {
                                const { label, value } = filterOption

                                const isOptionSelected =
                                    selectedResourceTypes.findIndex(
                                        (selectedResourceType) => selectedResourceType.value === value,
                                    ) !== -1

                                return (
                                    <S.FormGroupItem key={value}>
                                        <SelectableChip
                                            variant="outlined"
                                            size="small"
                                            focused={isOptionSelected}
                                            selected={isOptionSelected || !hasResourceTypesSelected}
                                            name={value.toString()}
                                            label={label}
                                            value={value}
                                            onChange={(event) => handleChangeResourceType(event, filterOption)}
                                        />
                                    </S.FormGroupItem>
                                )
                            })}
                        </S.FormGroup>
                    </S.FiltersGroup>
                )}

                {kpisFilters && (
                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>{kpisFilters.label}</S.FiltersGroupTitle>

                        <S.FormGroup as="ul">
                            <S.FormGroupItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!hasKpisSelected}
                                            onChange={(event) => handleChangeKpi(event)}
                                            name="ALL"
                                        />
                                    }
                                    label="All Metrics"
                                />
                            </S.FormGroupItem>

                            {kpisFilters.options.map((filterOption) => {
                                const { label, value } = filterOption

                                const isOptionChecked =
                                    selectedKpis.findIndex((selectedKpi) => selectedKpi.value === value) !== -1

                                return (
                                    <S.FormGroupItem key={value}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isOptionChecked}
                                                    onChange={(event) => handleChangeKpi(event, filterOption)}
                                                    name={value.toString()}
                                                />
                                            }
                                            label={label}
                                        />
                                    </S.FormGroupItem>
                                )
                            })}
                        </S.FormGroup>
                    </S.FiltersGroup>
                )}
            </S.Filters>
        </S.FiltersWrapper>
    )
}

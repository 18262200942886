import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'
import { SaveCatalogResponse } from 'api/actions/catalogs/catalogsActions.types'
import { saveCatalog } from 'api/actions/catalogs/catalogsActions'

export const useSaveCatalog = () => {
    const queryClient = useQueryClient()

    const mutation = useMutation<SaveCatalogResponse, AxiosError, string>({
        mutationKey: [Mutations.saveCatalog],
        mutationFn: saveCatalog,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [Queries.getCatalogs] })
            queryClient.invalidateQueries({ queryKey: [Queries.getCatalogsInfinite] })

            queryClient.refetchQueries({ queryKey: [Queries.getMyCatalogs] })
            queryClient.invalidateQueries({ queryKey: [Queries.getMyCatalogs] })
        },
    })

    return mutation
}

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    CompleteIntroPayload,
    CompleteTourPayload,
    SendOrganizationEmailPayload,
    SendOrganizationEmailResponse,
    SendTrainingEmailPayload,
} from './userMetadataActions.types'

export const sendTrainingEmail = async ({ values, userUUID }: SendTrainingEmailPayload): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.userMetadata.sendTrainingEmail(userUUID), values)

    return response.data
}

export const sendOrganizationEmail = async (
    values: SendOrganizationEmailPayload,
): Promise<SendOrganizationEmailResponse> => {
    const response = await diversioApiV2.post(endpoints.userMetadata.sendOrganizationEmail, values)

    return response.data
}

export const completeIntro = async ({ userUUID }: CompleteIntroPayload): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.userMetadata.completeIntro(userUUID))

    return response.data
}

export const completeTour = async ({ userUUID }: CompleteTourPayload): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.userMetadata.completeTour(userUUID))

    return response.data
}

import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { GetMyCatalogsResponse } from 'api/actions/catalogs/catalogsActions.types'
import { getMyCatalogs } from 'api/actions/catalogs/catalogsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetMyCatalogs = () => {
    const query = useQuery<GetMyCatalogsResponse, AxiosError>({
        queryKey: [Queries.getMyCatalogs],
        queryFn: getMyCatalogs,
        ...preventRefetchQueryOptions,
    })

    return query
}

import { generatePath } from 'react-router-dom'

import { generateCompanyURI } from './../sagas/helpers/generateCompanyURI'

export const endpoints = {
    configuration: '/configuration/',
    auth: {
        login: '/login/',
        initializeSso: '/sso/dashboard/initiate/',
        ssoCallback: '/sso/dashboard/callback/',
        requestPasswordReset: '/request-password-reset/',
        resetPassword: '/reset-password/',
        requestVerificationCode: '/send-verification-code/',
        validateVerificationCode: '/verify-verification-code/',
        enableMfa: (userUUID: string): string => generatePath('usermetadata/:userUUID/enroll-2fa/', { userUUID }),
    },
    home: {
        eventReminders: '/home/event-reminders/',
        solutions: {
            blogs: '/home/wordpress-blog-post/',
            tools: '/home/company/companyUUID/popular-tools/',
        },
        peopleSurveyed: '/home/company/companyUUID/roles-surveyed/',
        diversityBreakdownByRole: '/home/company/companyUUID/diversity-breakdown-by-role/',
        inclusionInsights: '/home/company/companyUUID/inclusion-insights/',
        inclusionHeatmap: '/home/company/companyUUID/inclusion-scorecard/',
        diversityBreakdown: '/home/company/companyUUID/diversity-breakdown/',
        announcements: '/home/company/companyUUID/announcement/',
        pastSurveys: '/home/company/companyUUID/past-surveys/',
        inspirationalQuotes: '/home/inspirational-quote/',
        deiSummary: '/home/company/companyUUID/dei-summary/',
        riskBanner: '/home/company/companyUUID/risk-banner/',
        progressBanners: '/home/company/companyUUID/progress-banners/',
        nps: '/home/company/companyUUID/past-nps/',
    },
    analyze: {
        companyFilters: '/company-filters/',
        diversity: {
            companyProfiles: '/diversity-company-profile-filter/',
            companyProfilesOptions: '/diversity-company-profile-filter/filters/',
            diversityData: '/diversity-data/',
            diversityDataOverTime: '/diversity-data-over-time/',
        },
        inclusion: {
            companyProfiles: '/inclusion-company-profile-filter/',
            companyProfilesOptions: '/inclusion-company-profile-filter/filters/',
            inclusionScore: '/inclusion-scores/',
            inclusionScoreOverTime: '/inclusion-scores-over-time/',
            freeTexts: '/inclusion-free-texts/',
            freeTextsFilters: '/free-text-filters/',
            bespokeFreeTexts: '/bespoke-free-texts/',
            panel: '/analyze/company/companyUUID/inclusion-side-panel/',
            trainingsPanel: '/analyze/company/companyUUID/trainings-side-panel/',
            allTrainingsPanel: '/company/companyUUID/trainings/trainings-side-panel-list/',
        },
    },
    mySolutions: {
        reports: `/my-solutions${generateCompanyURI('/my-reports/')}`,
    },
    programs: {
        programFilters: '/programs-filters/',
        allPrograms: '/programs/',
        saveProgram: '/save-program/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
    },
    recommended: {
        saveProgram: '/save-program/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
        recommendedInsights: '/insight-data/',
        goalOptions: '/solution-goal-card-options/',
        myGoals: '/my-goals/',
        deleteGoal: '/delete-my-goal/',
        recommendedPrograms: '/recommended-programs/',
    },
    catalogs: {
        list: '/company/companyUUID/catalogs/',
        filters: '/company/companyUUID/catalogs/catalog-filters/',
        saveCatalog: (id: string): string => generatePath('/company/companyUUID/catalogs/:id/save-catalog/', { id }),
        deleteCatalog: (id: string): string =>
            generatePath('/company/companyUUID/catalogs/:id/delete-catalog/', { id }),
        myCatalogs: '/my-solutions/company/companyUUID/my-catalogs/',
    },
    trainingsCatalogs: {
        list: '/company/companyUUID/trainings/',
        filters: '/company/companyUUID/trainings/training-filters/',
    },
    goals: {
        myGoals: '/my-goals/',
        savedSolutions: '/saved-solutions/',
        activeSolutions: '/active-solutions/',
        archivedSolutions: '/archived-solutions/',
        myTools: '/my-tools/',
        goalOptions: '/solution-goal-card-options/',
        deleteProgram: '/delete-program/',
        changeProgramStatus: '/change-program-status/',
        deleteGoal: '/delete-my-goal/',
        insightData: '/insight-data/',
        deleteTool: '/delete-tool/',
    },
    lumi: {
        createJobAdvert: '/lumi/company/companyUUID/job-adverts/',
        processJobAdvert: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/process/', { jobUUID }),
        getJobAdvertDetail: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/', { jobUUID }),
        deleteJobAdvert: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/', { jobUUID }),
        createJobAdvertItem: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/items/', { jobUUID }),
        getJobAdvertItemList: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/items/', { jobUUID }),
        uploadJobFile: (jobUUID: string, jobFileUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/items/:jobFileUUID/', {
                jobUUID,
                jobFileUUID,
            }),
        deleteJobFile: (jobUUID: string, jobFileUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/items/:jobFileUUID/', {
                jobUUID,
                jobFileUUID,
            }),
        downloadJobAdvert: (jobUUID: string): string =>
            generatePath('/lumi/company/companyUUID/job-adverts/:jobUUID/download-processed-files/', { jobUUID }),
    },
    tooltips: {
        list: '/company/companyUUID/tooltips/',
        vote: (id: string): string => generatePath('/company/companyUUID/tooltips/:id/vote/', { id }),
        unVote: (id: string): string => generatePath('/company/companyUUID/tooltips/:id/unvote/', { id }),
    },
    benchmarks: {
        national: {
            list: '/company/companyUUID/benchmark-census/',
            setPrimary: (id: string) => generatePath('company/companyUUID/benchmark-census/:id/set-primary/', { id }),
        },
        industry: {
            list: '/company/companyUUID/benchmark-industry/',
            setPrimary: (id: string) => generatePath('company/companyUUID/benchmark-industry/:id/set-primary/', { id }),
        },
    },
    userMetadata: {
        sendTrainingEmail: (userUUID: string): string =>
            generatePath('/usermetadata/:userUUID/send-training-email/', { userUUID }),
        sendOrganizationEmail: '/company/companyUUID/send-organization-email/',
        completeIntro: (userUUID: string): string =>
            generatePath('/usermetadata/:userUUID/complete-introsplash/', { userUUID }),
        completeTour: (userUUID: string): string =>
            generatePath('/usermetadata/:userUUID/complete-tour/', { userUUID }),
    },
}

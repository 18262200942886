import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetInspirationalQuotesResponse } from './inspirationalQuotesActions.types'

export const getInspirationalQuotes = async (): Promise<GetInspirationalQuotesResponse> => {
    const response = await diversioApiV2.get(endpoints.home.inspirationalQuotes)

    return response.data
}

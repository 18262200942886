import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Mutations } from 'api/mutations.enum'
import { Queries } from 'api/queries.enum'
import { deleteCatalogFromSaved } from 'api/actions/catalogs/catalogsActions'

export const useDeleteCatalogFromSaved = () => {
    const queryClient = useQueryClient()

    const mutation = useMutation<void, AxiosError, string>({
        mutationKey: [Mutations.deleteCatalogFromSaved],
        mutationFn: deleteCatalogFromSaved,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [Queries.getCatalogs] })
            queryClient.invalidateQueries({ queryKey: [Queries.getCatalogsInfinite] })
            queryClient.invalidateQueries({ queryKey: [Queries.getMyCatalogs] })
        },
    })

    return mutation
}

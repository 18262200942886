import React, { useMemo, useState } from 'react'

import { useDebounce } from 'hooks/useDebounce'
import { Catalog, CatalogFilterOption, TrainingLevel } from 'api/actions/catalogs/catalogsActions.types'

import { TrainingsContext } from './TrainingsContext'
import { TrainingsContextControllerProps } from './TrainingsContextController.types'

export const TrainingsContextController = ({ children }: TrainingsContextControllerProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [selectedLevel, setSelectedLevel] = useState<TrainingLevel | 'ALL'>('ALL')
    const [selectedKpis, setSelectedKpis] = useState<CatalogFilterOption[]>([])
    const [selectedTraining, setSelectedTraining] = useState<Catalog | null>(null)
    const debouncedSearchValue = useDebounce(searchValue, 500)

    const handleChangeSearchValue = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const handleChangeSelectedLevel = (selectedLevel: TrainingLevel | 'ALL') => {
        setSelectedLevel(selectedLevel)
    }

    const handleChangeSelectedKpis = (selectedKpis: CatalogFilterOption[]) => {
        setSelectedKpis(selectedKpis)
    }

    const handleChangeSelectedTraining = (selectedTraining: Catalog | null) => {
        setSelectedTraining(selectedTraining)
    }

    const contextValue = useMemo(
        () => ({
            searchValue,
            debouncedSearchValue: debouncedSearchValue,
            onChangeSearchValue: handleChangeSearchValue,
            selectedLevel,
            onChangeSelectedLevel: handleChangeSelectedLevel,
            selectedKpis,
            onChangeSelectedKpis: handleChangeSelectedKpis,
            selectedTraining,
            onChangeSelectedTraining: handleChangeSelectedTraining,
        }),
        [debouncedSearchValue, searchValue, selectedKpis, selectedLevel, selectedTraining],
    )

    return <TrainingsContext.Provider value={contextValue}>{children}</TrainingsContext.Provider>
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { useGetActiveCompanyInformation } from 'hooks/useGetActiveCompany'
import { AppRoute } from 'routing/AppRoute.enum'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { GoalsContent } from 'components/Tour/CustomSteps/Goals'
import { useVersion } from 'hooks/useVersion'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { Queries } from 'api/queries.enum'
import { useCompleteTour } from 'hooks/metadata/useCompleteTour'

import { TourContext } from './../tourContext/TourContext'
import { Step } from './../tourContext/TourContext.types'
import { TourContextControllerProps } from './TourContextController.types'

export const TourContextController = ({ children }: TourContextControllerProps) => {
    const {
        companyInformation: { permissions },
    } = useGetActiveCompanyInformation()

    const queryClient = useQueryClient()

    const { mutate } = useCompleteTour()

    const { data } = useUserMetadata()

    const userUUID = data?.uuid || ''

    const { version } = useVersion()

    const defaultIsTourActiveState = useMemo(() => {
        if (version !== 'v2') {
            return false
        }

        if (!data?.showTour) {
            return false
        }

        if (!permissions.home) {
            return false
        }

        return true
    }, [data?.showTour, permissions.home, version])

    const [tourStepIndex, setTourStepIndex] = useState(0)

    const [isTourActive, setIsTourActive] = useState(defaultIsTourActiveState)

    const [isTourCompletedCelebrationActive, setIsTourCompletedCelebrationActive] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (defaultIsTourActiveState) {
            setIsTourActive(true)
        }
    }, [defaultIsTourActiveState])

    useEffect(() => {
        if (isTourActive) {
            setTourStepIndex(0)
            setIsTourCompletedCelebrationActive(false)

            history.push(generateCompanyURI(AppRoute.Home))
        }
    }, [history, isTourActive])

    const steps: Step[] = [
        {
            page: generateCompanyURI(AppRoute.Home),
            title: 'Welcome to Diversio!',
            content: "We're really happy to have you here! Take a quick tour to get familiar with the platform.",
            permission: permissions.home,
        },
        {
            page: generateCompanyURI(AppRoute.Home),
            title: 'Homepage',
            content: 'Check the Homepage for a quick overview of your key analytics!',
            permission: permissions.home,
            youtubeId: 'ig1y4awWY2o',
        },
        {
            page: generateCompanyURI(AppRoute.AnalyzeDiversity),
            title: 'People: Employee Representation',
            content:
                'Your People page offers valuable insights into representation within your organization. Use filters, explore breakdowns, and create profiles for deeper analysis.',
            permission: permissions.analyze,
            youtubeId: 'AEqTB3EIXSQ',
        },
        {
            page: generateCompanyURI(AppRoute.AnalyzeInclusion),
            title: 'Culture: Employee Experience',
            content:
                "Your Culture page provides an in-depth view of your employees' experiences across KPIs. Be sure to review the written responses at the bottom for additional insights.",
            permission: permissions.analyze,
            youtubeId: '1vYDQawgyf0',
        },
        {
            page: generateCompanyURI(AppRoute.SolutionGoals),
            title: 'How to set goals',
            content: <GoalsContent />,
            permission: permissions.solutionGoals,
        },
        {
            page: generateCompanyURI(AppRoute.Resources),
            title: 'Resources for the workplace',
            content:
                'After diving into your insights, head to the Resources page for solutions to any pain point KPIs!',
            permission: permissions.solutionCatalog,
            youtubeId: 'hT3FGvCCMPY',
        },
        {
            page: generateCompanyURI(AppRoute.MySolutions),
            title: 'My Solutions',
            content: 'Look for the save icon on Resources and Trainings to add them to your My Solutions page.',
            permission: permissions.solutionMySolutions,
            youtubeId: '_HdyPBC5-X0',
        },
        {
            page: generateCompanyURI(AppRoute.Lumi),
            title: 'Lumi',
            content: 'Lumi is here to help you augment your job ads to attract a wider, more diverse talent pool.',
            permission: permissions.lumiJobAds,
            youtubeId: 'cPn10p9KbyQ',
        },
        {
            page: generateCompanyURI(AppRoute.Home),
            title: 'We’re here to help you along the way',
            content:
                'Click the Information Icon located in the corner of elements to learn what they mean, why they matter, and how to use them.',
            permission: permissions.home,
            youtubeId: 'AYOXBxY0JpQ',
        },
    ].filter(({ permission }) => permission)

    const handleInitTour = () => {
        setIsTourActive(true)
    }

    const handlePreviousStep = useCallback(() => {
        if (tourStepIndex > 0) {
            setTourStepIndex(tourStepIndex - 1)

            history.push(steps[tourStepIndex - 1].page)
        } else {
            setIsTourActive(false)
        }
    }, [history, steps, tourStepIndex])

    const handleCompleteTour = useCallback(
        () =>
            mutate(
                { userUUID },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries([Queries.getUserMetadata])

                        setIsTourActive(false)
                        setIsTourCompletedCelebrationActive(true)
                    },
                },
            ),
        [mutate, queryClient, userUUID],
    )

    const handleNextStep = useCallback(() => {
        if (tourStepIndex < steps.length - 1) {
            setTourStepIndex(tourStepIndex + 1)

            history.push(steps[tourStepIndex + 1].page)
        } else {
            handleCompleteTour()
        }
    }, [handleCompleteTour, history, steps, tourStepIndex])

    const handleFinishTourCompletedCelebration = () => {
        setIsTourCompletedCelebrationActive(false)
    }

    const value = useMemo(
        () => ({
            tourStepIndex,
            isTourActive,
            isTourCompletedCelebrationActive,
            onFinishTourCompletedCelebration: handleFinishTourCompletedCelebration,
            onInitTour: handleInitTour,
            steps,
            onPreviousStep: handlePreviousStep,
            onNextStep: handleNextStep,
        }),
        [handleNextStep, handlePreviousStep, isTourActive, isTourCompletedCelebrationActive, steps, tourStepIndex],
    )

    return <TourContext.Provider value={value}>{children}</TourContext.Provider>
}

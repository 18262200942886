export enum Mutations {
    login = 'login',
    initializeSso = 'initializeSso',
    ssoCallback = 'ssoCallback',
    requestResetPassword = 'requestResetPassword',
    resetPassword = 'resetPassword',
    requestVerificationCode = 'requestVerificationCode',
    validateVerificationCode = 'validateVerificationCode',
    createDiversityCompanyProfile = 'createDiversityCompanyProfile',
    updateDiversityCompanyProfile = 'updateDiversityCompanyProfile',
    deleteDiversityCompanyProfile = 'deleteDiversityCompanyProfile',
    createInclusionCompanyProfile = 'createInclusionCompanyProfile',
    updateInclusionCompanyProfile = 'updateInclusionCompanyProfile',
    deleteInclusionCompanyProfile = 'deleteInclusionCompanyProfile',
    exportDiversityData = 'exportDiversityData',
    exportDiversityDataOverTime = 'exportDiversityDataOverTime',
    exportDiversityProfilesData = 'exportDiversityProfilesData',
    exportInclusionData = 'exportInclusionData',
    exportInclusionDataOverTime = 'exportInclusionDataOverTime',
    exportFreeTexts = 'exportFreeTexts',
    exportBespokeFreeTexts = 'exportBespokeFreeTexts',
    saveCatalog = 'saveCatalog',
    deleteCatalogFromSaved = 'deleteCatalogFromSaved',
    saveTraining = 'saveTraining',
    deleteTrainingFromSaved = 'deleteTrainingFromSaved',
    updateUserMetadataFeatureFlags = 'updateUserMetadataFeatureFlags',
    voteTooltip = 'voteTooltip',
    enableMFA = 'enableMFA',
    setPrimaryNationalBenchmark = 'setPrimaryNationalBenchmark',
    setPrimaryIndustryBenchmark = 'setPrimaryIndustryBenchmark',
    sendTrainingEmail = 'sendTrainingEmail',
    sendOrganizationEmail = 'sendOrganizationEmail',
    createJobAdvert = 'createJobAdvert',
    deleteJobAdvert = 'deleteJobAdvert',
    uploadJobFile = 'uploadJobFile',
    deleteJobFile = 'deleteJobFile',
    createJobAdvertItem = 'createJobAdvertItem',
    downloadJobAdvert = 'downloadJobAdvert',
    getJobAdvertItemList = 'getJobAdvertItemList',
    completeIntro = 'completeIntro',
    completeTour = 'completeTour',
}

import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

import { CloseButton } from '../CatalogItemDetails/catalogItemDetails.styles'

export const Modal = styled(Dialog)`
    .MuiDialog-paper {
        width: 544px;
        min-height: 600px;
        max-height: 85vh;
        padding: 0;
        box-shadow: 6px 12px 60px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        background-color: ${({ theme }) => theme.colors.primaryWhite};
        position: relative;
        overflow: visible;
    }

    ${CloseButton} {
        &.dds-button {
            display: none;
        }
    }
`

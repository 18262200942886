import React from 'react'

import { TrainingsContextController } from './context/TrainingsContextController'
import { Trainings } from './trainings'

export const TrainingsContainer = () => {
    return (
        <TrainingsContextController>
            <Trainings />
        </TrainingsContextController>
    )
}

import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'

export const downloadFile = async (params: Record<string, any>, endpoint: string): Promise<void> => {
    const response = await diversioApiV2.get(endpoint, {
        responseType: 'arraybuffer',
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    const fileBlob = new Blob([response.data], { type: 'application/zip' })
    const downloadUrl = window.URL.createObjectURL(fileBlob)
    const link = document.createElement('a')

    link.href = downloadUrl

    const filenameRegex = /filename=(.+)$/
    const match = response.headers['content-disposition'].match(filenameRegex)

    if (match) {
        link.setAttribute('download', match[1])
    } else {
        link.setAttribute('download', 'file.zip')
    }

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

import React, { useEffect, useState } from 'react'

import { CatalogItemDetails } from 'components/Catalog/CatalogItemDetails'

import { CatalogItemSidePanelProps } from './catalogItemSidePanel.types'
import * as S from './catalogItemSidePanel.styles'

export const CatalogItemSidePanel = (props: CatalogItemSidePanelProps) => {
    const { selectedCatalogItem } = props

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (selectedCatalogItem) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [selectedCatalogItem])

    return (
        <S.CatalogItemSidePanelWrapper isOpen={isOpen}>
            <CatalogItemDetails {...props} />
        </S.CatalogItemSidePanelWrapper>
    )
}

import { getFontStyle, makeLineClamp } from '@diversioteam/diversio-ds'
import styled, { css } from 'styled-components'

export const Training = styled.button<{ isSelected?: boolean }>`
    padding: 18px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.primary30};
    cursor: pointer;
    text-decoration: none;
    background-color: inherit;

    &:hover {
        border-color: ${({ theme }) => theme.colors.primary35};
    }

    ${({ isSelected }) => {
        if (isSelected) {
            return css`
                border-width: 2px;
                border-color: ${({ theme }) => theme.colors.primary40};
                border-style: solid;
            `
        }
    }}
`

export const TrainingHeader = styled.span<{ isSelected?: boolean }>`
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}

    span:last-child {
        white-space: nowrap;
    }

    ${Training}:hover, ${Training}:focus-visible && {
        color: ${({ theme }) => theme.colors.primary50};

        div[class*='-icon'] {
            background-color: ${({ theme }) => theme.colors.primary50};
        }
    }

    ${({ isSelected }) => {
        if (isSelected) {
            return css`
                color: ${({ theme }) => theme.colors.primary50};

                div[class*='-icon'] {
                    background-color: ${({ theme }) => theme.colors.primary50};
                }
            `
        }
    }}
`

export const TrainingTitle = styled.span`
    margin-left: 6px;

    ${makeLineClamp(1)}

    word-break: break-all;
`

export const TrainingDescription = styled.span<{ isSelected?: boolean }>`
    text-align: left;
    color: ${({ theme }) => theme.colors.secondary500};

    ${Training}:focus-visible && {
        color: ${({ theme }) => theme.colors.primary50};
    }

    ${({ isSelected }) => {
        if (isSelected) {
            return css`
                color: ${({ theme }) => theme.colors.primary50};
            `
        }
    }}

    ${makeLineClamp(3)}

    ${getFontStyle('p03Regular')}
`

import { loadDiversityDataOverTime } from 'sagas/network'
import { cleanString, stringifyQueryParams } from 'utils'
import { useCallback } from 'react'
import { isEmpty } from 'underscore'
import { ColumnDef } from '@tanstack/react-table'

import { Result as DiversityDataResult } from 'api/actions/analyze/diversityData/diversityDataActions.types'
import {
    DiversityDataResponse,
    TableData as DiversityTableData,
} from 'api/actions/analyze/diversityData/diversityDataActions.types'
import { TransformedData as DiversityOverTimeTransformedData } from 'api/actions/analyze/diversityData/diversityDataOverTimeActions.types'
import { DiversityDataOverTimeParams } from 'api/actions/analyze/diversityData/diversityDataOverTimeActions.types'
import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'

import { useBenchmarks } from './benchmarks/useBenchmarks'
// TODO: @sahanarula, make a dictionary of all the colors to be easily accessible in JS
const blue = 'blue'
const tangerine = 'tangerine'
const gray = 'gray'
const purple = 'purple'

const ascAlphaText = 'A to Z'
const ascNumericText = 'Low to High'
const descAlphaText = 'Z to A'
const descNumericText = 'High to Low'

export const useDiversityLineChart = (params: DiversityDataOverTimeParams) => {
    const { primaryNationalBenchmark, primaryIndustryBenchmark, secondaryIndustryBenchmark } = useBenchmarks()

    const transformData = (rawData: DiversityDataResponse[]): DiversityOverTimeTransformedData => {
        const firstRow = rawData[0]

        const companyName = firstRow.results?.[0]?.company?.label

        const industries = (() => {
            if (primaryIndustryBenchmark) {
                if (secondaryIndustryBenchmark) {
                    return [primaryIndustryBenchmark.label, secondaryIndustryBenchmark.label]
                }

                return [primaryIndustryBenchmark.label]
            }

            return []
        })()

        const nationalAverage = primaryNationalBenchmark?.label

        const tableColumns: ColumnDef<DiversityTableData>[] = [
            {
                accessorKey: 'group',
                header: 'Group',
                meta: {
                    tooltip: {
                        sort: {
                            asc: ascAlphaText,
                            desc: descAlphaText,
                        },
                    },
                },
            },
            {
                accessorKey: cleanString(companyName),
                header: companyName,
                meta: {
                    color: gray,
                    tooltip: {
                        sort: {
                            asc: ascNumericText,
                            desc: descNumericText,
                        },
                    },
                },
            },
        ]

        if (nationalAverage) {
            tableColumns.push({
                accessorKey: cleanString(nationalAverage),
                header: nationalAverage,
                meta: {
                    color: purple,
                    tooltip: {
                        sort: {
                            asc: ascNumericText,
                            desc: descNumericText,
                        },
                    },
                },
            })
        }

        if (industries.length) {
            industries.map((industry, idx) => {
                tableColumns.push({
                    accessorKey: cleanString(industry),
                    header: industry,
                    meta: {
                        color: idx === 1 ? tangerine : blue,
                        tooltip: {
                            sort: {
                                asc: ascNumericText,
                                desc: descNumericText,
                            },
                        },
                    },
                })
            })
        }

        const parseTableData = (dataItem: DiversityDataResult): DiversityTableData => {
            let parsedTableData: DiversityTableData = {
                group: dataItem.group,
                [cleanString(companyName)]: dataItem.company.score,
            }

            if (nationalAverage && !isEmpty(dataItem.nationalBenchmarks)) {
                parsedTableData = {
                    ...parsedTableData,
                    [cleanString(nationalAverage)]: dataItem.nationalBenchmarks?.find(
                        ({ uuid }) => uuid === primaryNationalBenchmark?.uuid,
                    )?.score,
                }
            }

            if (dataItem.industries?.length) {
                const industryData: { [key: string]: number } = {}

                dataItem.industries.map((industry) => {
                    industryData[cleanString(industry.label)] = industry.score
                })

                parsedTableData = {
                    ...parsedTableData,
                    ...industryData,
                }
            }

            if (dataItem.subRows?.length) {
                parsedTableData = {
                    ...parsedTableData,
                    subRows: dataItem.subRows.map((subRow) => parseTableData(subRow)),
                }
            }

            return parsedTableData
        }

        const tableData = rawData.map((tableGroup) => {
            return {
                group: tableGroup.yearQuarterLabel,
                subRows: tableGroup.results.map((group) => parseTableData(group)),
            }
        })

        return {
            chartResults: [],
            tableResults: {
                columns: tableColumns,
                data: tableData,
            },
            group: firstRow?.group,
        }
    }

    return useQuery<DiversityDataResponse[], Error, DiversityOverTimeTransformedData>({
        queryKey: [Queries.getDiversityDataOverTime, ...Object.values(params)],
        queryFn: () => {
            return loadDiversityDataOverTime(stringifyQueryParams(params))
        },
        select: useCallback(transformData, [
            primaryNationalBenchmark,
            primaryIndustryBenchmark,
            secondaryIndustryBenchmark,
        ]),
    })
}

import React, { useEffect, useState } from 'react'
import { QuoteCard, QuoteCardProps, QuoteCardSkeleton } from '@diversioteam/diversio-ds'
import shuffle from 'lodash/shuffle'
import range from 'lodash/range'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetInspirationalQuotes } from 'hooks/useGetInspirationalQuotes'

const InspirationalQuotesWithoutEB = () => {
    const selectedQuoteIdx = Number(0)

    const { isLoading, data: quotes } = useGetInspirationalQuotes()
    const [quoteIdx, setQuoteIdx] = useState(selectedQuoteIdx)
    const [shuffledQuotesIndices, setShuffledQuotesIndices] = React.useState<number[]>([])

    const incrementQuote = () => {
        if (!quotes) {
            return
        }

        const isLastQuoteIdx = quoteIdx === shuffledQuotesIndices.length - 1
        const nextQuoteIdx = isLastQuoteIdx ? 0 : quoteIdx + 1

        setQuoteIdx(nextQuoteIdx)
    }

    useEffect(() => {
        if (!quotes) {
            return
        }

        const quotesIndices = range(quotes.length)

        setShuffledQuotesIndices(shuffle(quotesIndices))
    }, [quotes])

    if (isLoading) {
        return <QuoteCardSkeleton data-testid="inspirational-quotes-skeleton" />
    }

    if (!quotes || !quotes.length) {
        return null
    }

    const data: QuoteCardProps = {
        ...quotes[shuffledQuotesIndices[quoteIdx]],
        onReload: incrementQuote,
    }

    return <QuoteCard {...data} />
}

export const InspirationalQuotes = withErrorBoundary(InspirationalQuotesWithoutEB, {})

import React, { useState } from 'react'

import { useGetMyCatalogs } from 'hooks/catalogs/useGetMyCatalogs'
import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'
import { CatalogItem } from 'components/Catalog/CatalogItem'
import { Catalog } from 'api/actions/catalogs/catalogsActions.types'
import { CatalogItemModal } from 'components/Catalog/CatalogItemModal'

import * as S from './myResources.styles'

export const MyResources = () => {
    const { data, isLoading } = useGetMyCatalogs()
    const [selectedCatalogItem, setSelectedCatalogItem] = useState<Catalog | null>(null)

    const handleChangeSelectedCatalogItem = (catalog: Catalog | null) => {
        return setSelectedCatalogItem(catalog)
    }

    if (data && data.length === 0) {
        return null
    }

    return (
        <S.MyResources>
            <S.Title>Your Resources</S.Title>

            <S.List>
                {isLoading && new Array(4).fill('').map((_, index) => <CatalogItemSkeleton key={index} />)}

                {data?.map(({ uuid, catalog }) => (
                    <CatalogItem
                        key={uuid}
                        {...catalog}
                        isSelected={false}
                        selectedCatalogItem={selectedCatalogItem}
                        onChangeSelectedCatalogItem={handleChangeSelectedCatalogItem}
                    />
                ))}

                <CatalogItemModal
                    selectedCatalogItem={selectedCatalogItem}
                    onChangeSelectedCatalogItem={handleChangeSelectedCatalogItem}
                />
            </S.List>
        </S.MyResources>
    )
}

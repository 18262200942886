import styled from 'styled-components'
import { getFontStyle, NoResults as DdsNoResults } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const Category = styled.section`
    padding-top: 16px;
    margin-top: 60px;
    border-top: 1px solid ${({ theme }) => theme.colors.secondary100Transparent};

    &:last-child {
        height: 100%;
    }
`

export const Header = styled.header`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin-bottom: 18px;
`

export const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.colors.primary50};
    cursor: pointer;

    ${getFontStyle('cardHeaderBoldSmall')}
`

export const List = styled.ul`
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    gap: 16px;
    list-style-type: none;

    ${media.md} {
        grid-template-columns: repeat(2, 1fr);
    }
`

export const LoadMore = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`

export const NoResults = styled(DdsNoResults)`
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    border-radius: 20px;
`

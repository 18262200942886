import React from 'react'

import { SurveysContextController } from 'context/surveys/surveysContextController/SurveysContextController'
import { TourContextController } from 'context/tour/tourContextController/TourContextController'

import { DashboardProvidersProps } from './DashboardProviders.types'

export const DashboardProviders = ({ children }: DashboardProvidersProps) => (
    <TourContextController>
        <SurveysContextController>{children}</SurveysContextController>
    </TourContextController>
)

import React from 'react'
import { UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { getCatalogMetadata, isTrainingMetadata } from 'components/Catalog/catalog.helpers'

import { CatalogItemProps } from './catalogItem.types'
import * as S from './catalogItem.styles'

export const CatalogItem = ({
    selectedCatalogItem,
    onChangeSelectedCatalogItem,
    isTrainingsView,
    ...props
}: CatalogItemProps) => {
    const { uuid, title, thumbnail, thumbnailAltText, catalogType } = props

    const metadata = getCatalogMetadata(props)

    if (!metadata) {
        return null
    }

    const isSelected = selectedCatalogItem?.uuid === uuid

    const handleClickCatalogItem = () => onChangeSelectedCatalogItem(props)

    const isTraining = isTrainingMetadata(metadata)

    const hasDiversioChip = catalogType === 'Report' || catalogType === 'Tool'

    const hasChips = hasDiversioChip || metadata.isNew || isTraining

    return (
        <S.CatalogItemWrapper isSelected={isSelected} onClick={handleClickCatalogItem}>
            <S.Title>{title}</S.Title>

            <S.Details>
                {hasChips && (
                    <S.ChipsList>
                        {hasDiversioChip && (
                            <S.ChipsListItem>
                                <Chip label="Diversio" size="xsmall" />
                            </S.ChipsListItem>
                        )}

                        {metadata.isNew && (
                            <S.ChipsListItem>
                                <Chip label="New" size="xsmall" />
                            </S.ChipsListItem>
                        )}

                        {isTraining && (
                            <>
                                {metadata.isPopular && (
                                    <S.ChipsListItem>
                                        <Chip label="Popular" size="xsmall" />
                                    </S.ChipsListItem>
                                )}
                                {metadata.isLive && (
                                    <S.ChipsListItem>
                                        <Chip label="Live" size="xsmall" />
                                    </S.ChipsListItem>
                                )}
                                {metadata.isAsync && (
                                    <S.ChipsListItem>
                                        <Chip label="Self-paced" size="xsmall" />
                                    </S.ChipsListItem>
                                )}
                            </>
                        )}
                    </S.ChipsList>
                )}
                {!isTrainingsView && metadata.category.label}
                {isTraining && isTrainingsView && <> {metadata.trainingLevel} · Level</>}
            </S.Details>

            <S.Thumbnail categoryKey={metadata.category.value}>
                {thumbnail && <img src={thumbnail} alt={thumbnailAltText || ''} />}
            </S.Thumbnail>
        </S.CatalogItemWrapper>
    )
}

import {
    GetEventRemindersResponse,
    GetEventRemindersTransformedResponse,
} from 'api/actions/home/eventReminders/eventRemindersActions.types'
import { convertTo24Hour } from 'utils/convertTo24Hour/convertTo24Hour'

export const transformData = (data: GetEventRemindersResponse): GetEventRemindersTransformedResponse => {
    if (data.length === 0) {
        return []
    }

    return data.map((event) => {
        const { year, month, day, time } = event.eventDateInfo

        const dateString = [
            year,
            month ? new Date(`${month} 01 2000`).toLocaleDateString('en', { month: '2-digit' }) : '',
            day,
        ]
            .filter(Boolean)
            .join('-')

        return {
            date: dateString,
            time: time ? convertTo24Hour(time) : time,
            title: event.title,
            description: event.description,
            link: event.link,
            linkProps: {
                target: '_blank',
            },
        }
    })
}

import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import {
    AlertIcon,
    Autocomplete,
    getComponentClassName,
    HeatMapPanel,
    LightbulbIcon,
    SupportIcon,
    TextField,
} from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'
import { _btoa_json_encode } from 'components/Analyze/Filters/utils'
import { ErrorBoundary } from '@sentry/react'
import { Skeleton } from '@material-ui/lab'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useGetInclusionPanelTrainings } from 'hooks/inclusion/useGetInclusionPanelTrainings'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { AnalyzeView } from 'types/analyze.enum'
import { useGetKpiScoreMetadata } from 'hooks/inclusion/useGetKpiScoreMetadata'
import { useInclusionData } from 'hooks/useInclusionData'
import { useGetInclusionPanelAllTrainings } from 'hooks/inclusion/useGetInclusionPanelAllTrainings'
import { useGetInclusionPanel } from 'hooks/inclusion/useGetInclusionPanel'
import { CatalogItemModal } from 'components/Catalog/CatalogItemModal'
import { Catalog } from 'api/actions/catalogs/catalogsActions.types'

import { useFilters } from './../../../hooks/useFilters'
import { TableVariants } from './../heatmap.types'
import { filterUnavailableData } from './../heatmap.utils'
import { Training } from './Training'
import { isCellProfile, isCellSideColumn, getTabType, panelTableToSubtitleMapping } from './panel.utils'
import { HeatmapPanelColors, HeatmapTablePanelProps, InclusionPanelParamsProps } from './panel.types'
import * as S from './panel.styles'

const HeatmapTablePanelWithoutEB = ({
    handleClose,
    selectedCellInfo,
    selectedCellData,
    selectedTab,
    selectedTabType,
    setPanelVisible,
    onCellClick,
}: HeatmapTablePanelProps) => {
    const [selectedCatalogItem, setSelectedCatalogItem] = useState<Catalog | null>(null)
    const [hasClickedLoadMoreTrainings, setHasClickedLoadMoreTrainings] = useState(false)

    const { selectedFiltersPayload, setProfilesActive, setDefaultSelectedProfileId, isFiltersInProfilesActive } =
        useFilters()

    const { primaryIndustryBenchmark, secondaryIndustryBenchmark } = useBenchmarks()

    const { survey } = useSurveys()

    useEffect(() => {
        setHasClickedLoadMoreTrainings(false)
    }, [selectedCellData])

    const filtersInProfilesParams = (() => {
        if (selectedTab !== 'target_groups') {
            return {}
        }

        if (isFiltersInProfilesActive) {
            return {
                filters_in_profiles: isFiltersInProfilesActive,
            }
        }

        return {}
    })()

    const filterParams = {
        ...selectedFiltersPayload,
        ...filtersInProfilesParams,
        year: parseYearQuarter(survey),
        key: selectedTab,
    }

    const profileParams = {
        year: filterParams.year,
    }

    const companyFiltersParams = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyFilters = useGetCompanyFilters(companyFiltersParams)
    const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, profileParams)

    const { data: inclusionData, isLoading: isLoadingInclusionData } = useInclusionData({
        ...filterParams,
        type: selectedTabType,
    })

    const selectedCell = selectedCellData.cell

    const isTargetGroupsTab = selectedTab === 'target_groups'

    const isCompanyRow = selectedCellData.row?.code === 'company'
    const isBenchmarkRow = [primaryIndustryBenchmark?.uuid, secondaryIndustryBenchmark?.uuid].includes(
        selectedCellData.row?.code,
    )
    const isSelectedCellSideColumn = isCellSideColumn(selectedCell)
    const isSelectedCellProfile = isCellProfile(selectedCell)

    const getTargetValue = () => {
        const targetType = selectedCellData.row?.type

        if (isCompanyRow) {
            return 'company'
        } else if (isTargetGroupsTab) {
            return targetType
        } else {
            return undefined
        }
    }

    const getInclusionPanelPayload = (): InclusionPanelParamsProps => {
        return {
            tab_type: {
                key: selectedTab,
                type: getTabType(selectedTab, queryGetCompanyFilters.data?.filtersPane.inclusion),
            },
            row_type: {
                code: selectedCellData.row.code,
                type: getTargetValue(),
            },
            metrics_type: selectedCellData?.tableType,
            column_type: selectedCellData.column
                ? {
                      key: selectedCellData.tableType === 'InclusionMetrics' ? selectedCellData.column.key : undefined,
                      code: selectedCellData.tableType !== 'InclusionMetrics' ? selectedCellData.column.key : undefined,
                  }
                : undefined,
        }
    }

    const inclusionPanelPayload = getInclusionPanelPayload()

    const inclusionPanelParams = useMemo(
        () => ({
            inclusion_side_panel_data: _btoa_json_encode(inclusionPanelPayload),
            ...filterParams,
        }),
        [inclusionPanelPayload],
    )

    const inclusionPanelTrainingsParams = useMemo(
        () => ({
            trainings_side_panel_data: _btoa_json_encode(inclusionPanelPayload),
            ...filterParams,
        }),
        [inclusionPanelPayload],
    )

    const { data: kpiScoreMetaData } = useGetKpiScoreMetadata()
    const { data: inclusionPanelData, isLoading: isLoadingInclusionPanel } = useGetInclusionPanel(inclusionPanelParams)
    const { data: inclusionPanelTrainingsData } = useGetInclusionPanelTrainings(inclusionPanelTrainingsParams, {
        enabled: selectedCellData.tableType === 'InclusionMetrics' && selectedCellData.cell.isWeakness,
    })

    const inclusionPanelAllTrainingsSearchParams = new URLSearchParams(
        inclusionPanelTrainingsData?.allTrainingsUrl.split('?')[1],
    )
    const inclusionPanelAllTrainingsParams = Object.fromEntries(inclusionPanelAllTrainingsSearchParams.entries())

    const {
        data: inclusionPanelAllTrainingsData,
        fetchNextPage: fetchNextPageInclusionPanelAllTrainings,
        isFetchingNextPage: isFetchingNextPageInclusionPanelAllTrainings,
    } = useGetInclusionPanelAllTrainings(
        {
            ...inclusionPanelAllTrainingsParams,
            page_size: 3,
        },
        {
            enabled: typeof inclusionPanelTrainingsData?.allTrainingsUrl === 'string' && hasClickedLoadMoreTrainings,
        },
    )

    console.log(isFetchingNextPageInclusionPanelAllTrainings)

    const inclusionPanelAllTrainingsResults = inclusionPanelAllTrainingsData?.pages

    const hasNextPageInclusionPanelAllTrainings =
        inclusionPanelAllTrainingsResults &&
        inclusionPanelAllTrainingsResults[inclusionPanelAllTrainingsResults.length - 1].next !== null

    const getSelectedProfile = () => {
        return queryGetCompanyProfiles.data?.profiles.filter((profile) => {
            return profile.label === selectedCell?.inclusionTitle
        })[0]
    }

    const getSelectedProfileGroups = () => {
        const selectedProfile = getSelectedProfile()

        const profiles = selectedProfile?.filters.map(({ selectedOptions, ...rest }) => ({
            ...rest,
            options: selectedOptions,
        }))

        return profiles
    }

    const profileSharedProps = {
        profiles: getSelectedProfileGroups(),
        profilesTitle: filterUnavailableData(selectedCell?.inclusionTitle),
        onEditProfile: () => {
            const selectedProfile = getSelectedProfile()

            setDefaultSelectedProfileId?.(selectedProfile?.uuid || '')
            setProfilesActive?.(true)
        },
    }

    const getSharedProps = () => {
        if (selectedCell?.rowType === 'profile') {
            return profileSharedProps
        }
    }

    const heatmapPanelColors: HeatmapPanelColors =
        inclusionPanelData?.scoreBreakdown?.scaleType === 'three_point'
            ? ['green20', 'primary20', 'lobster10']
            : ['green30', 'green20', 'primary20', 'lobster10', 'lobster20', 'fuchsia10']

    const getResponses = () => {
        if (isBenchmarkRow) {
            return undefined
        }

        if (isLoadingInclusionPanel) {
            return kpiScoreMetaData?.defaultLabels?.map((label) => ({
                name: label,
                value: 0,
                color: 'primaryGray0',
                valueRenderer: () => <Skeleton width={50} />,
            }))
        }

        const totalScores =
            inclusionPanelData?.scoreBreakdown?.values?.reduce((reducer, response) => {
                return reducer + response.value
            }, 0) || 0
        const format = inclusionPanelData?.scoreBreakdown?.format

        return inclusionPanelData?.scoreBreakdown?.values.map((response, idx) => ({
            name: response.label,
            value: format === 'count' ? Math.round((response.value / totalScores) * 100) : response.value,
            color: heatmapPanelColors[idx],
            valueRenderer: () => {
                if (format === 'count') {
                    return response.value
                } else {
                    return `${response.value}%`
                }
            },
        }))
    }

    const getDominantGroups = () => {
        const groups = inclusionPanelData?.dominantGroupData?.current.data?.map((group) => group.label) || []

        return {
            groups,
            groupsTitle: 'Dominant Groups',
        }
    }

    const getDiversityGroups = () => {
        return {
            groups: inclusionPanelData?.diversityData.map(({ label }) => label) || [],
            groupsTitle: filterUnavailableData(selectedCell?.inclusionTitle),
        }
    }

    const getDefaultGroups = () => {
        const hasTitle = selectedCell.inclusionTitle !== undefined

        const hasSubtitle =
            selectedCell.inclusionSecondarySubtitle !== undefined || selectedCell.inclusionSubtitle !== undefined

        if (!hasTitle || !hasSubtitle) {
            return
        }

        if (isTargetGroupsTab || isCompanyRow) {
            return
        }

        return {
            groups: [
                `${selectedCell.inclusionTitle}・${(
                    selectedCell.inclusionSecondarySubtitle || selectedCell.inclusionSubtitle
                )?.replace('Bespoke / ', '')}`,
            ],
            groupsTitle: 'Groups',
        }
    }

    const getGroups = () => {
        if (inclusionPanelData?.dominantGroupData) {
            return getDominantGroups()
        } else if (inclusionPanelData?.diversityData) {
            return getDiversityGroups()
        } else {
            return getDefaultGroups()
        }
    }

    const getGroupsProps = () => {
        const groupsProps = getGroups()

        if (!groupsProps?.groups.length && isSelectedCellSideColumn && !isSelectedCellProfile) {
            setPanelVisible(false)
        }

        return groupsProps
    }

    const sharedProps = getSharedProps()

    const renderTopSlot = () => {
        if (isSelectedCellSideColumn) {
            return null
        }

        if (isLoadingInclusionData) {
            return <Skeleton width="100%" height={50} variant="rect" style={{ borderRadius: '12px' }} />
        }

        if (!inclusionData) {
            return null
        }

        const inclusionMetricsOptions = inclusionData.inclusionMetrics.columns.map((item, index) => ({
            ...item,
            category: 'Inclusion',
            tableVariant: TableVariants.Inclusion,
            column: index,
        }))

        const bespokeMetricsOptions = inclusionData.bespokeMetrics.columns.map((item, index) => ({
            ...item,
            category: 'Bespoke',
            tableVariant: TableVariants.Bespoke,
            column: index,
        }))

        const engagementMetricsOptions = inclusionData.engagementMetrics.columns.map((item, index) => ({
            ...item,
            category: 'Engagement',
            tableVariant: TableVariants.Engagement,
            column: index,
        }))

        const options = [...inclusionMetricsOptions, ...bespokeMetricsOptions, ...engagementMetricsOptions]

        const renderOption = (option: typeof options[0]) => (
            <>
                {option.title}
                <span className={`${getComponentClassName('Autocomplete')}-option-category`}>{option.category}</span>
            </>
        )

        const autocompleteKey = inclusionPanelPayload.column_type?.key || inclusionPanelPayload.column_type?.code || ''

        return (
            <>
                <Autocomplete
                    key={autocompleteKey}
                    getOptionLabel={(option) => option.title}
                    multiple={false}
                    options={options}
                    renderInput={(params) => <TextField {...params} placeholder="Select Metric" label="Metric" />}
                    groupBy={(option) => option.category}
                    renderOption={renderOption}
                    defaultValue={options.find(
                        ({ key }) =>
                            key === inclusionPanelPayload.column_type?.key ||
                            key === inclusionPanelPayload.column_type?.code,
                    )}
                    onChange={(e, values) => {
                        if (!values) {
                            return
                        }

                        if (!selectedCellInfo) {
                            return
                        }

                        onCellClick(values.tableVariant, selectedCellInfo.row, values.column)
                    }}
                />

                {selectedCell.kpi?.description && (
                    <S.MetricDescription>{selectedCell.kpi?.description}</S.MetricDescription>
                )}
            </>
        )
    }

    const handleChangeSelectedCatalogItem = (catalog: Catalog | null) => {
        setSelectedCatalogItem(catalog)
    }

    const renderBottomSlot = () => {
        if (isSelectedCellSideColumn) {
            return null
        }

        return (
            <>
                {inclusionPanelTrainingsData && (
                    <>
                        {inclusionPanelTrainingsData.trainings.map((training) => (
                            <Training
                                key={training.uuid}
                                {...training}
                                isSelected={selectedCatalogItem?.uuid === training.uuid}
                                onClick={() => setSelectedCatalogItem(training)}
                            />
                        ))}

                        {inclusionPanelAllTrainingsResults?.flatMap(({ results }) =>
                            results.map((training) => (
                                <Training
                                    key={training.uuid}
                                    {...training}
                                    isSelected={selectedCatalogItem?.uuid === training.uuid}
                                    onClick={() => setSelectedCatalogItem(training)}
                                />
                            )),
                        )}

                        {inclusionPanelTrainingsData.count > 2 &&
                            (hasNextPageInclusionPanelAllTrainings || !hasClickedLoadMoreTrainings) && (
                                <S.ViewAll
                                    onClick={() => {
                                        setHasClickedLoadMoreTrainings(true)

                                        fetchNextPageInclusionPanelAllTrainings()
                                    }}
                                >
                                    Load More
                                </S.ViewAll>
                            )}

                        {isFetchingNextPageInclusionPanelAllTrainings && (
                            <Skeleton
                                width="100%"
                                height={100}
                                variant="rect"
                                style={{ borderRadius: '12px', flexShrink: 0 }}
                            />
                        )}

                        <CatalogItemModal
                            selectedCatalogItem={selectedCatalogItem}
                            onChangeSelectedCatalogItem={handleChangeSelectedCatalogItem}
                        />
                    </>
                )}

                {selectedCell?.rowType !== 'benchmark' && (
                    <S.Training as="a" href="https://diversio.com/platform/dei-consulting/" target="_blank">
                        <S.TrainingHeader>
                            <SupportIcon type="bold" width={16} height={16} />
                            <S.TrainingTitle>Book a Free Consultation</S.TrainingTitle>
                        </S.TrainingHeader>

                        <S.TrainingDescription>
                            Consult a subject matter expert about improving Inclusive Culture in the workplace
                        </S.TrainingDescription>
                    </S.Training>
                )}
            </>
        )
    }

    const responses = getResponses()

    const getHeatMapTablePanelMessages = () => {
        const messages: { icon: ReactNode; text: string }[] = []

        if (inclusionPanelData?.dominantGroupData?.changed) {
            const previousGroups =
                inclusionPanelData?.dominantGroupData?.previous.data?.map((group) => group.label) || []

            messages.push({
                icon: <AlertIcon type="line" />,
                text: `Previous dominant group was: ${previousGroups.join(', ')}`,
            })
        }

        if (responses?.some(({ name }) => name === 'No Response')) {
            const tableName =
                panelTableToSubtitleMapping[
                    selectedCellData?.tableType as keyof typeof panelTableToSubtitleMapping
                ]?.toLowerCase()

            messages.push({
                icon: <AlertIcon type="line" />,
                text: `The ${tableName} breakdown represents everyone who responded. The ${tableName} Score only contains the responses from people who identified their demographics.`,
            })
        }

        if (selectedCell.insight) {
            messages.push({ icon: <LightbulbIcon type="line" />, text: selectedCell.insight })
        }

        return messages
    }

    return (
        <S.HeatMapTablePanel>
            <ErrorBoundary>
                <HeatMapPanel
                    onClose={handleClose}
                    onEditProfile={undefined}
                    surveyQuestion={filterUnavailableData(selectedCell?.kpi?.questionTitle)}
                    {...sharedProps}
                    responses={getResponses()}
                    {...getGroupsProps()}
                    topSlot={renderTopSlot()}
                    bottomSlot={renderBottomSlot()}
                    messages={getHeatMapTablePanelMessages()}
                />
            </ErrorBoundary>
        </S.HeatMapTablePanel>
    )
}

export const HeatmapTablePanel = withErrorBoundary<HeatmapTablePanelProps>(HeatmapTablePanelWithoutEB, {})

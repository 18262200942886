import React from 'react'

import * as S from './goals.styles'

export const GoalsContent = () => {
    return (
        <S.StepsList>
            <S.StepItem>
                <S.StepTitle>Select an Insight</S.StepTitle>

                <S.StepDescription>Identify pain points</S.StepDescription>
            </S.StepItem>

            <S.StepItem>
                <S.StepTitle>Create a Goal</S.StepTitle>

                <S.StepDescription>Set score improvements</S.StepDescription>
            </S.StepItem>

            <S.StepItem>
                <S.StepTitle>Recommended Programs</S.StepTitle>

                <S.StepDescription>Find solutions & best practices</S.StepDescription>
            </S.StepItem>
        </S.StepsList>
    )
}

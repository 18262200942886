import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { catalogImages } from 'components/Catalog/catalog.helpers'

export const CatalogItemWrapper = styled.li<{ isSelected: boolean }>`
    display: grid;
    grid-template-areas:
        'title thumbnail'
        'details thumbnail';
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr min-content;
    grid-gap: 24px;
    min-height: 168px;
    padding: 24px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    cursor: pointer;
    padding: 24px;

    outline: ${({ isSelected, theme }) => {
        if (isSelected) {
            return `4px solid ${theme.colors.primary40}`
        }
    }};
`

export const Title = styled.h3`
    grid-area: title;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p01Semibold')}
`

export const Details = styled.div`
    grid-area: details;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

export const Thumbnail = styled.div<{ categoryKey?: string }>`
    grid-area: thumbnail;
    height: 120px;
    width: 120px;
    flex-shrink: 0;
    border-radius: 12px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary10};
    background-image: ${({ categoryKey }) => {
        if (!categoryKey) {
            return
        }

        return catalogImages[categoryKey] ? `url(${catalogImages[categoryKey]})` : 'none'
    }};
    background-size: cover;
    background-position: 50% 50%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export const ChipsList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    list-style-type: none;
`

export const ChipsListItem = styled.li`
    .dds-chip {
        border: 1px solid ${({ theme }) => theme.colors.primary20};
        background-color: ${({ theme }) => theme.colors.primary10};
    }

    .dds-chip__label {
        color: ${({ theme }) => theme.colors.primary50};

        ${getFontStyle('captionBoldM')};
    }
`

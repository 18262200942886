import React from 'react'
import { BookLessonIcon } from '@diversioteam/diversio-ds'

import { TrainingProps } from './training.types'
import * as S from './training.styles'

export const Training = ({ uuid, title, description, isSelected, ...props }: TrainingProps) => {
    return (
        <S.Training key={uuid} isSelected={isSelected} {...props}>
            <S.TrainingHeader isSelected={isSelected}>
                <BookLessonIcon type="bold" width={16} height={16} />
                <S.TrainingTitle>{title}</S.TrainingTitle>
                <span>・Training</span>
            </S.TrainingHeader>

            <S.TrainingDescription isSelected={isSelected}>{description}</S.TrainingDescription>
        </S.Training>
    )
}

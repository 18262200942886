import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { getTools } from 'api/actions/home/solutions/solutionsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { GetToolsResponse } from 'api/actions/home/solutions/solutionsActions.types'

export const useGetTools = () => {
    const query = useQuery<GetToolsResponse, AxiosError>({
        queryKey: [Queries.getTools],
        queryFn: getTools,
        ...preventRefetchQueryOptions,
    })

    return query
}

import React from 'react'
import { UnstableChip as Chip } from '@diversioteam/diversio-ds'

import { Accordion } from './../Accordion'
import { TrainingDetailProps } from './trainingDetails.types'
import * as S from './trainingDetails.styles'

export const TrainingDetails = ({
    isLive,
    isAsync,
    certificateProgramName,
    trainingLevel,
    objectives,
}: TrainingDetailProps) => {
    return (
        <>
            <S.ChipsList>
                {isLive && (
                    <S.ChipsListItem>
                        <Chip label="Live" size="xsmall" />

                        <S.ChipDescription>
                            Course is offered by a live instructor either in person or virtually
                        </S.ChipDescription>
                    </S.ChipsListItem>
                )}

                {isAsync && (
                    <S.ChipsListItem>
                        <Chip label="Self-paced" size="xsmall" />

                        <S.ChipDescription>Course is offered inside eLearning portal</S.ChipDescription>
                    </S.ChipsListItem>
                )}

                {certificateProgramName && (
                    <S.ChipsListItem>
                        <Chip label={certificateProgramName} size="xsmall" />

                        <S.ChipDescription>
                            This course is part of the {certificateProgramName} certificate program
                        </S.ChipDescription>
                    </S.ChipsListItem>
                )}

                {trainingLevel && (
                    <S.ChipsListItem>
                        <Chip label={trainingLevel} size="xsmall" />

                        <S.ChipDescription>Level of difficulty</S.ChipDescription>
                    </S.ChipsListItem>
                )}
            </S.ChipsList>

            {objectives.length > 0 && (
                <S.Objectives>
                    <S.ObjectivesTitle>Objectives</S.ObjectivesTitle>

                    {objectives.map((objective, index) => (
                        <Accordion {...objective} key={index} />
                    ))}
                </S.Objectives>
            )}
        </>
    )
}

import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import {
    GetEventRemindersResponse,
    GetEventRemindersTransformedResponse,
} from 'api/actions/home/eventReminders/eventRemindersActions.types'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { getEventReminders } from 'api/actions/home/eventReminders/eventRemindersActions'

import { transformData } from './transformData'

export const useGetEventReminders = () => {
    return useQuery<GetEventRemindersResponse, AxiosError, GetEventRemindersTransformedResponse>({
        queryKey: [Queries.getEventReminders],
        queryFn: getEventReminders,
        select: transformData,
        ...preventRefetchQueryOptions,
    })
}

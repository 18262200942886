import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { completeIntro } from 'api/actions/userMetadata/userMetadataActions'
import { CompleteIntroPayload } from 'api/actions/userMetadata/userMetadataActions.types'
import { Mutations } from 'api/mutations.enum'

export const useCompleteIntro = () => {
    const mutation = useMutation<void, AxiosError, CompleteIntroPayload>({
        mutationKey: [Mutations.completeIntro],
        mutationFn: completeIntro,
    })

    return mutation
}

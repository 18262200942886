import { useMutation } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'

import { Mutations } from 'api/mutations.enum'
import { GetBespokeFreeTextsParams } from 'api/actions/analyze/freeTexts/freeTextsActions.types'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import TAXONOMIES from 'utils/taxonomies'
import { exportBespokeFreeTexts } from 'api/actions/analyze/freeTexts/freeTextsActions'

export const useExportBespokeFreeTexts = (defaultParams: GetBespokeFreeTextsParams) => {
    const { survey } = useSurveys()

    const selectedSurveyParams = survey
        ? {
              year: survey.year,
              quarter: survey.quarter,
          }
        : {}

    const params: GetBespokeFreeTextsParams = { export_format: 'csv', ...selectedSurveyParams, ...defaultParams }

    const mutation = useMutation<void, Error, void>({
        mutationKey: [Mutations.exportBespokeFreeTexts, params],
        mutationFn: () => {
            return exportBespokeFreeTexts(params)
        },
        onSuccess: () => {
            mixpanel.track(TAXONOMIES.ANALYZE_INCLUSION_FREE_TEXT_EXPORT)
        },
    })

    return mutation
}

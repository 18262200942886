import React, { ChangeEvent } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Checkbox, SearchBox } from '@diversioteam/diversio-ds'

import { CatalogFilterOption, TrainingLevel } from 'api/actions/catalogs/catalogsActions.types'
import { useGetTrainingCatalogFilters } from 'hooks/catalogs/useGetTrainingCatalogFilters'

import { useTrainings } from './../hooks/useTrainings'
import * as S from './filters.styles'
import { FiltersSkeleton } from './skeleton'

export const Filters = () => {
    const {
        searchValue,
        onChangeSearchValue,
        selectedKpis,
        onChangeSelectedKpis,
        selectedLevel,
        onChangeSelectedLevel,
    } = useTrainings()

    const { data, isLoading } = useGetTrainingCatalogFilters()

    if (isLoading) {
        return <FiltersSkeleton />
    }

    const kpisFilters = data?.filters.find(({ name }) => name === 'kpis')
    const levelFilters = data?.filters.find(({ name }) => name === 'training_level')

    const hasKpisSelected = selectedKpis.length > 0

    const handleChangeKpi = (event: ChangeEvent<HTMLInputElement>, filterOption?: CatalogFilterOption) => {
        const { name, checked } = event.target

        if (!kpisFilters) return

        if (name === 'ALL') {
            return onChangeSelectedKpis([])
        }

        if (!filterOption) return

        const isSelectAllState = selectedKpis.length === kpisFilters.options.length - 1

        if (checked) {
            const updatedKpis = isSelectAllState ? [] : [...selectedKpis, filterOption]

            onChangeSelectedKpis(updatedKpis)
        } else {
            const updatedKpis = selectedKpis.filter((selectedKpi) => selectedKpi.value.toString() !== name)

            onChangeSelectedKpis(updatedKpis)
        }
    }

    const handleChangeSelectedLevel = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
        return onChangeSelectedLevel(value as TrainingLevel)
    }

    return (
        <S.FiltersWrapper>
            <S.Filters>
                <S.FiltersGroup>
                    <SearchBox
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        defaultExpanded
                        keepExpanded
                        placeholder="Search"
                    />
                </S.FiltersGroup>

                {levelFilters && (
                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>Training Level</S.FiltersGroupTitle>

                        <RadioGroup
                            aria-label="training level"
                            name="training level"
                            value={selectedLevel}
                            onChange={handleChangeSelectedLevel}
                        >
                            <FormControlLabel value="ALL" control={<Radio color="default" />} label="All" />

                            {levelFilters.options.map(({ label, value }) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    control={<Radio color="default" />}
                                    label={label}
                                />
                            ))}
                        </RadioGroup>
                    </S.FiltersGroup>
                )}

                {kpisFilters && (
                    <S.FiltersGroup>
                        <S.FiltersGroupTitle>{kpisFilters.label}</S.FiltersGroupTitle>

                        <S.FormGroup as="ul">
                            <S.FormGroupItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!hasKpisSelected}
                                            onChange={(event) => handleChangeKpi(event)}
                                            name="ALL"
                                        />
                                    }
                                    label="All Metrics"
                                />
                            </S.FormGroupItem>

                            {kpisFilters.options.map((filterOption) => {
                                const { label, value } = filterOption

                                const isOptionChecked =
                                    selectedKpis.findIndex((selectedKpi) => selectedKpi.value === value) !== -1

                                return (
                                    <S.FormGroupItem key={value}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isOptionChecked}
                                                    onChange={(event) => handleChangeKpi(event, filterOption)}
                                                    name={value.toString()}
                                                />
                                            }
                                            label={label}
                                        />
                                    </S.FormGroupItem>
                                )
                            })}
                        </S.FormGroup>
                    </S.FiltersGroup>
                )}
            </S.Filters>
        </S.FiltersWrapper>
    )
}

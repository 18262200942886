import React, { useEffect, useState } from 'react'
import { Fade } from '@material-ui/core'

import { CatalogItemDetails } from './../CatalogItemDetails'
import { CatalogItemModalProps } from './catalogItemModal.types'
import * as S from './catalogItemModal.styles'

export const CatalogItemModal = (props: CatalogItemModalProps) => {
    const { selectedCatalogItem, onChangeSelectedCatalogItem } = props

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (selectedCatalogItem) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [selectedCatalogItem])

    const handleCloseModal = () => {
        onChangeSelectedCatalogItem(null)
    }

    return (
        <S.Modal open={isOpen} onClose={handleCloseModal}>
            <Fade in={isOpen}>
                <CatalogItemDetails {...props} />
            </Fade>
        </S.Modal>
    )
}

import { AxiosError } from 'axios'
import { parseYearQuarter } from 'utils'

import { GetInclusionHeatmapResponse } from 'api/actions/home/inclusionHeatmap/inclusionHeatmapActions.types'
import { Queries } from 'api/queries.enum'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { getInclusionHeatmap } from 'api/actions/home/inclusionHeatmap/inclusionHeatmapActions'
import { useQuery } from 'hooks/useQuery'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const useGetInclusionHeatmap = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetInclusionHeatmapResponse, AxiosError>({
        queryKey: [Queries.getInclusionHeatmap, params],
        queryFn: () => getInclusionHeatmap(params),
        enabled: survey !== null,
        ...preventRefetchQueryOptions,
    })

    return query
}

import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { GetCatalogFiltersResponse } from 'api/actions/catalogs/catalogsActions.types'
import { getCatalogFilters } from 'api/actions/catalogs/catalogsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'

export const useGetCatalogFilters = () => {
    const query = useQuery<GetCatalogFiltersResponse, AxiosError>({
        queryKey: [Queries.getCatalogFilters],
        queryFn: getCatalogFilters,
        ...preventRefetchQueryOptions,
    })

    return query
}

import { parseYearQuarter } from 'utils'
import { AxiosError } from 'axios'

import { GetInclusionInsightsResponse } from 'api/actions/home/inclusionInsights/inclusionInsightsActions.types'
import { Queries } from 'api/queries.enum'
import { getInclusionInsights } from 'api/actions/home/inclusionInsights/inclusionInsightsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

export const useGetInclusionInsights = () => {
    const { survey } = useSurveys()

    const params = {
        year: parseYearQuarter(survey),
    }

    const query = useQuery<GetInclusionInsightsResponse, AxiosError>({
        queryKey: [Queries.getInclusionInsights, params],
        queryFn: () => getInclusionInsights(params),
        enabled: survey !== null,
        ...preventRefetchQueryOptions,
    })

    return query
}

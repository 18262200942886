import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetProgressBannersResponse } from './progressBannerActions.types'

export const getProgressBanners = async (): Promise<GetProgressBannersResponse> => {
    const response = await diversioApiV2.get(endpoints.home.progressBanners)

    return response.data
}

import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    GetInclusionPanelAllTrainingsParams,
    GetInclusionPanelAllTrainingsResponse,
    GetInclusionPanelParams,
    GetInclusionPanelResponse,
    GetInclusionPanelTrainingsParams,
    GetInclusionPanelTrainingsResponse,
} from './inclusionActions.types'

export const getInclusionPanel = async (params?: GetInclusionPanelParams): Promise<GetInclusionPanelResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.panel, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getInclusionPanelTrainings = async (
    params?: GetInclusionPanelTrainingsParams,
): Promise<GetInclusionPanelTrainingsResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.trainingsPanel, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getInclusionPanelAllTrainings = async (
    params?: GetInclusionPanelAllTrainingsParams,
): Promise<GetInclusionPanelAllTrainingsResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.allTrainingsPanel, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

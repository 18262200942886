import { isEmpty } from 'lodash'
import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'
import { useGetKpiScoreMetadata } from 'hooks/inclusion/useGetKpiScoreMetadata'
import { GetInclusionPanelParams, GetInclusionPanelResponse } from 'api/actions/inclusion/inclusionActions.types'
import { getInclusionPanel } from 'api/actions/inclusion/inclusionActions'

export const useGetInclusionPanel = (params?: GetInclusionPanelParams, options?: { enabled?: boolean }) => {
    const { data } = useGetKpiScoreMetadata()

    const query = useQuery<GetInclusionPanelResponse, AxiosError>({
        queryKey: [Queries.getInclusionPanel, params],
        queryFn: () => getInclusionPanel(params),
        enabled: !isEmpty(data) && options?.enabled,
    })

    return query
}

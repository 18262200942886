import React, { useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'

import { Queries } from 'api/queries.enum'
import { useGetJobAdvertDetail } from 'hooks/lumi/useGetJobAdvertDetail'
import { useLumi } from '../hooks/useLumi'
import { Stage } from '../context/LumiContext.types'
import TAXONOMIES from 'utils/taxonomies'

import * as S from './progressBar.styles'

export const ProgressBar = () => {
    const [calculatedPercentage, setCalculatedPercentage] = useState(0)
    const [displayedPercentage, setDisplayedPercentage] = useState(0)
    const [_hasMixpanelTrackedJob, setHasMixpanelTrackedJob] = useState(false)

    const { stage, onChangeStage, jobId, acceptedFiles } = useLumi()

    const queryClient = useQueryClient()

    const { data: queryGetJobAdvertDetailData } = useGetJobAdvertDetail(jobId, {
        enabled: stage === Stage.Processing,
        onError: () => {
            mixpanel.track(TAXONOMIES.LUMI_TRANSFORM_JOB_AD, {
                status: 'Error',
                jobId,
            })
            onChangeStage(Stage.Error)
        },
    })

    const numberOfFiles = acceptedFiles.length

    const numberOfCompletedItems = queryGetJobAdvertDetailData?.statuses['Completed'] || 0
    const numberOfFailedItems = queryGetJobAdvertDetailData?.statuses['Failed'] || 0

    useEffect(() => {
        setCalculatedPercentage(() => {
            const total = numberOfFiles

            if (total === 0) {
                return 0
            }

            return Math.floor(((numberOfCompletedItems + numberOfFailedItems) / total) * 100)
        })
    }, [numberOfCompletedItems, numberOfFailedItems, numberOfFiles, queryGetJobAdvertDetailData])

    useEffect(() => {
        const intervalSpeed = calculatedPercentage > displayedPercentage ? 25 : 1000 * numberOfFiles

        const interval = setInterval(() => {
            setDisplayedPercentage((prev) => {
                if (calculatedPercentage === 0) {
                    return Math.min(prev + 1, 95)
                }

                if (prev < calculatedPercentage) {
                    return calculatedPercentage
                }

                if (prev >= calculatedPercentage) {
                    return Math.min(prev + 1, 95)
                }

                clearInterval(interval)

                return prev
            })
        }, intervalSpeed)

        return () => clearInterval(interval)
    }, [calculatedPercentage, displayedPercentage])

    useEffect(() => {
        if (calculatedPercentage === 100 && displayedPercentage === 100) {
            setHasMixpanelTrackedJob((prevHasTracked) => {
                if (!prevHasTracked) {
                    mixpanel.track(TAXONOMIES.LUMI_TRANSFORM_JOB_AD, {
                        status: numberOfFailedItems > 0 ? 'Completed with Errors' : 'Completed',
                        jobId,
                        Uploaded: queryGetJobAdvertDetailData?.statuses.Uploaded || 0,
                        Pending: queryGetJobAdvertDetailData?.statuses.Pending || 0,
                        Processing: queryGetJobAdvertDetailData?.statuses.Processing || 0,
                        'Post-processing': queryGetJobAdvertDetailData?.statuses['Post-processing'] || 0,
                        Completed: queryGetJobAdvertDetailData?.statuses.Completed || 0,
                        Failed: queryGetJobAdvertDetailData?.statuses.Failed || 0,
                    })
                    return true
                }
                return prevHasTracked
            })

            const timeout = setTimeout(() => {
                if (numberOfFailedItems > 0) {
                    queryClient.cancelQueries([Queries.getJobAdvertDetail])
                    queryClient.removeQueries([Queries.getJobAdvertDetail])
                    queryClient.resetQueries([Queries.getJobAdvertDetail])

                    return onChangeStage(Stage.UpdateData)
                }

                return onChangeStage(Stage.Completed)
            }, 1000)

            return () => clearTimeout(timeout)
        }
    }, [calculatedPercentage, displayedPercentage, numberOfFailedItems])

    useEffect(() => {
        setHasMixpanelTrackedJob(false)
    }, [jobId])

    return (
        <S.ProgressBar>
            <S.BarBackground>
                <S.BarFill style={{ width: `${displayedPercentage}%` }}>{Math.round(displayedPercentage)}%</S.BarFill>
            </S.BarBackground>

            <S.Info>
                <span>1 min left</span>
            </S.Info>
        </S.ProgressBar>
    )
}

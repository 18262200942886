import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SolutionsTab, SolutionsTabSkeleton } from '@diversioteam/diversio-ds'

import { useGetTools } from 'hooks/useGetTools'
import { useGetBlogs } from 'hooks/useGetBlogs'
import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { AppRoute } from 'routing/AppRoute.enum'
import { MediaType } from 'types/common'
import { Catalog } from 'api/actions/catalogs/catalogsActions.types'
import { CatalogItemModal } from 'components/Catalog/CatalogItemModal'

const SolutionsWithoutEB = () => {
    const [selectedCatalogItem, setSelectedCatalogItem] = useState<Catalog | null>(null)

    const blogsQuery = useGetBlogs()
    const toolsQuery = useGetTools()

    const isLoading = blogsQuery.isLoading || toolsQuery.isLoading
    const hasData = blogsQuery.data || toolsQuery.data

    if (isLoading) {
        return <SolutionsTabSkeleton data-testid="solutions-skeleton" />
    }

    if (!hasData) {
        return null
    }

    const blogs = blogsQuery.data?.map((blog) => ({ ...blog, target: '_blank', href: blog.url })) || []
    const tools =
        toolsQuery.data?.map(({ title, description, url, toolMetadata, targetGroups, uuid }) => ({
            id: uuid,
            title,
            description: description || '',
            target: '_blank',
            href: url,
            category: toolMetadata?.category.label || '',
            targetGroups: targetGroups?.map(({ displayName }) => displayName).join(', '),
            mediaType: toolMetadata?.mediaType.toLowerCase() as MediaType,
        })) || []

    const handleToolClick = (toolId: string) => {
        const tool = toolsQuery.data?.find(({ uuid }) => toolId === uuid)

        if (tool) {
            setSelectedCatalogItem(tool)
        }
    }

    const handleChangeSelectedCatalogItem = (catalog: Catalog | null) => {
        setSelectedCatalogItem(catalog)
    }

    return (
        <>
            <SolutionsTab
                blogs={blogs}
                programs={[]}
                tools={tools}
                onToolClick={handleToolClick}
                buttonProps={{
                    blogs: {
                        component: 'a',
                        target: '_blank',
                        href: 'https://diversio.com/resources/articles/',
                    },
                    programs: {
                        component: Link,
                        to: generateCompanyURI(AppRoute.Programs),
                    },
                    tools: {
                        component: Link,
                        to: generateCompanyURI(AppRoute.Resources),
                    },
                }}
            />

            <CatalogItemModal
                selectedCatalogItem={selectedCatalogItem}
                onChangeSelectedCatalogItem={handleChangeSelectedCatalogItem}
            />
        </>
    )
}

export const Solutions = withErrorBoundary(SolutionsWithoutEB, {})

import React, { useCallback, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from 'actions'
import { useIdleTimer } from 'react-idle-timer'
import mixpanel from 'mixpanel-browser'

import { EnableMFA } from 'components/Auth/EnableMFA'
import { useEnableMfa } from 'hooks/auth/useEnableMfa/useEnableMfa'
import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { useRequestVerificationCode } from 'hooks/auth/useRequestVerificationCode/useRequestVerificationCode'
import TAXONOMIES from 'utils/taxonomies'

import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'
import { LocalStorageKey, VerificationTypeLocationState } from './../auth.types'

export const EnableMFAContainer = () => {
    const {
        isAuthenticated,
        accessToken,
        refreshToken,
        username,
        uuid: userUUID,
    } = useSelector((state: RootState) => state.auth)
    const [is2faSkipped, setIs2faSkipped] = useState(false)

    const mutationEnableMfa = useEnableMfa()
    const mutationRequestVerificationCode = useRequestVerificationCode()

    const dispatch = useDispatch()
    const location = useLocation()

    const isFirstTimeMfaUser = localStorage.getItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`) === 'true'

    const handleOnAction = () => {
        dispatch(Actions.doRefreshToken({ accessToken, refreshToken }))
    }

    const handleOnIdle = () => {
        dispatch(Actions.doSignout())
    }

    useIdleTimer({
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        timeout: 1000 * 60 * Number(process.env.REACT_APP_INACTIVE_EXPIRE_TIME),
        throttle: 1000 * 60 * Number(process.env.REACT_APP_TOKEN_REFRESH_TIME),
    })

    const requestVerificationCode = async (): Promise<boolean> => {
        try {
            mixpanel.track(TAXONOMIES.TWO_FACTOR_AUTHENTICATION_CODE_REQUESTED, {
                twoFactorClickedAt: new Date().toISOString(),
                username,
                verificationType: 'email',
            })

            await mutationRequestVerificationCode.mutateAsync({
                verification_type: 'email',
            })

            return true
        } catch (error) {
            return false
        }
    }

    const handleTurnOn2FAClick = useCallback(async (): Promise<boolean> => {
        try {
            await mutationEnableMfa.mutateAsync({
                payload: {
                    enroll: true,
                },
                userUUID,
            })

            if (isFirstTimeMfaUser) {
                await requestVerificationCode()
            }

            return true
        } catch (error) {
            dispatch(Actions.doSignout())

            return false
        }
    }, [mutationEnableMfa, mutationRequestVerificationCode])

    const handleRemindLaterClick = useCallback(async (): Promise<boolean> => {
        try {
            setIs2faSkipped(true)

            await mutationEnableMfa.mutateAsync({
                payload: {
                    enroll: false,
                },
                userUUID,
            })

            return true
        } catch (error) {
            dispatch(Actions.doSignout())

            return false
        }
    }, [mutationEnableMfa])

    // For first time users, clicking 'Turn on 2FA' takes them directly to 'email code verification' screen
    if (isFirstTimeMfaUser && mutationRequestVerificationCode.isSuccess) {
        const state: VerificationTypeLocationState = { verificationType: 'email' }

        return <Redirect to={{ pathname: AppRoute.TwoStepVerification, search: location.search, state }} />
    }

    // Clicking 'Remind me later' takes users to the dashboard
    if (is2faSkipped && mutationEnableMfa.isSuccess) {
        return <RedirectAuthenticatedUser />
    }

    if (mutationEnableMfa.isError || mutationRequestVerificationCode.isError) {
        dispatch(Actions.doSignout())

        return <Redirect to={AppRoute.Login} />
    }

    if (isAuthenticated) {
        return <RedirectAuthenticatedUser />
    }

    return <EnableMFA onClickRemindLater={handleRemindLaterClick} onClickTurnOn2FA={handleTurnOn2FAClick} />
}

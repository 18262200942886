import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetPastSurveysResponse } from './pastSurveysActions.types'

export const getPastSurveys = async (): Promise<GetPastSurveysResponse> => {
    const response = await diversioApiV2.get(endpoints.home.pastSurveys)

    return response.data
}

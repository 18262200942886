import React, { useEffect, useState } from 'react'
import { Button, CloseIcon, EmailIcon, KpiIcon, RedirectIcon, SaveIcon, Tooltip } from '@diversioteam/diversio-ds'

import { useSaveCatalog } from 'hooks/catalogs/useSaveCatalog'
import { useDeleteCatalogFromSaved } from 'hooks/catalogs/useDeleteCatalogFromSaved'
import { getCatalogMetadata, getCatalogMetadataKey } from 'components/Catalog/catalog.helpers'
import { CatalogMetadataKey, TrainingMetadata } from 'api/actions/catalogs/catalogsActions.types'

import { TrainingDetails } from './TrainingDetails'
import { Message } from './Message'
import { CatalogItemDetailsProps } from './catalogItemDetails.types'
import * as S from './catalogItemDetails.styles'

export const CatalogItemDetails = ({ selectedCatalogItem, onChangeSelectedCatalogItem }: CatalogItemDetailsProps) => {
    const [isMessageViewOpen, setIsMessageViewOpen] = useState(false)

    const mutationSaveCatalog = useSaveCatalog()
    const mutationDeleteCatalogFromSaved = useDeleteCatalogFromSaved()

    useEffect(() => {
        handleCloseMessageView()
    }, [selectedCatalogItem?.uuid])

    const handleClose = () => {
        onChangeSelectedCatalogItem(null)
    }

    const handleOpenMessageView = () => {
        setIsMessageViewOpen(true)
    }

    const handleCloseMessageView = () => {
        setIsMessageViewOpen(false)
    }

    if (!selectedCatalogItem) {
        return <div></div>
    }

    const { uuid, title, description, kpis, targetGroups, url, thumbnail, thumbnailAltText } = selectedCatalogItem

    const metadata = getCatalogMetadata(selectedCatalogItem)
    const metadataKey = getCatalogMetadataKey(selectedCatalogItem)

    if (!metadata) {
        return null
    }

    const { isSaved } = metadata

    const handleClickSaveButton = () => {
        if (isSaved) {
            return mutationDeleteCatalogFromSaved.mutate(uuid, {
                onSuccess: () => {
                    onChangeSelectedCatalogItem({
                        ...selectedCatalogItem,
                        [metadataKey]: {
                            ...metadata,
                            isSaved: false,
                            companyToolId: null,
                        },
                    })
                },
            })
        }

        if (!isSaved) {
            return mutationSaveCatalog.mutate(uuid, {
                onSuccess: ({ id }) => {
                    onChangeSelectedCatalogItem({
                        ...selectedCatalogItem,
                        [metadataKey]: {
                            ...metadata,
                            isSaved: true,
                            companyToolId: id,
                        },
                    })
                },
            })
        }
    }

    const renderButton = () => {
        if (metadataKey === CatalogMetadataKey.TrainingMetadata) {
            return (
                <>
                    <Button
                        component="a"
                        href={(metadata as TrainingMetadata).marketingUrl || ''}
                        target="_blank"
                        color="secondary"
                    >
                        Learn more
                    </Button>

                    <Button onClick={handleOpenMessageView} rightIcon={<EmailIcon type="line" />}>
                        Connect with sales
                    </Button>
                </>
            )
        }

        return (
            <Button component="a" href={url} rightIcon={<RedirectIcon type="line" />} target="_blank">
                Open {metadata.category.label}
            </Button>
        )
    }

    return (
        <S.CatalogItemDetailsWrapper>
            <Tooltip title="Close">
                <S.CloseButton color="transparent" iconOnly onClick={handleClose}>
                    <CloseIcon type="bold" />
                </S.CloseButton>
            </Tooltip>

            {isMessageViewOpen && (
                <Message
                    onCloseMessageView={handleCloseMessageView}
                    onCloseSidePanel={handleClose}
                    trainingTitle={title}
                />
            )}

            {!isMessageViewOpen && (
                <>
                    <S.Container>
                        <S.ThumbnailWrapper>
                            <S.Thumbnail categoryKey={metadata.category.value}>
                                {thumbnail && <img src={thumbnail} alt={thumbnailAltText || ''} />}
                            </S.Thumbnail>

                            <Tooltip title={isSaved ? 'Remove from Favorite' : 'Add to Favorite'}>
                                <S.SaveButton
                                    size="small"
                                    color={isSaved ? 'primary' : 'primaryWhite'}
                                    iconOnly
                                    onClick={handleClickSaveButton}
                                >
                                    <SaveIcon type={isSaved ? 'bold' : 'line'} />
                                </S.SaveButton>
                            </Tooltip>
                        </S.ThumbnailWrapper>

                        <S.Title>{title}</S.Title>

                        <S.Description>{description}</S.Description>

                        {metadataKey === CatalogMetadataKey.TrainingMetadata && (
                            <TrainingDetails {...(metadata as TrainingMetadata)} />
                        )}

                        {kpis && kpis.length > 0 && (
                            <S.Details>
                                <S.DetailsTitle>Impacted Inclusion Metrics</S.DetailsTitle>

                                <S.DetailsList>
                                    {kpis.map(({ name, code }) => (
                                        <S.DetailsListItem key={code}>
                                            <KpiIcon kpi={code} size="xsmall" /> {name}
                                        </S.DetailsListItem>
                                    ))}
                                </S.DetailsList>
                            </S.Details>
                        )}

                        {targetGroups && targetGroups.length > 0 && (
                            <S.Details>
                                <S.DetailsTitle>Target Groups Supported</S.DetailsTitle>

                                <S.DetailsList>
                                    {targetGroups.map(({ displayName, id }) => (
                                        <S.DetailsListItem key={id}>{displayName}</S.DetailsListItem>
                                    ))}
                                </S.DetailsList>
                            </S.Details>
                        )}
                    </S.Container>

                    <S.Buttons>{renderButton()}</S.Buttons>
                </>
            )}
        </S.CatalogItemDetailsWrapper>
    )
}

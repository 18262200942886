import React, { useState } from 'react'
import { MinusIcon, PlusIcon } from '@diversioteam/diversio-ds'

import { AccordionProps } from './accordion.types'
import * as S from './accordion.styles'

export const Accordion = ({ title, details }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <S.AccordionWrapper>
            <S.Header onClick={toggleAccordion}>
                <span>{title}</span>

                <span>{isOpen ? <MinusIcon type="line" /> : <PlusIcon type="line" />}</span>
            </S.Header>

            {isOpen && (
                <S.Content>
                    {details.map((detail, index) => (
                        <li key={index}>{detail}</li>
                    ))}
                </S.Content>
            )}
        </S.AccordionWrapper>
    )
}

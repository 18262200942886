import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const HeatMapTablePanel = styled.div`
    .dds-heat-map-panel {
        position: fixed;
        right: 0;
        top: 78px;
        height: calc(100% - 78px);
        z-index: 100;
    }

    .dds-heat-map-panel__close-button {
        top: 16px;
    }

    .dds-autocomplete {
        .MuiInputBase-input {
            ${getFontStyle('cardHeaderBoldSmall')}
        }
    }
`

export const ViewAll = styled.button`
    padding: 10px 24px;
    color: ${({ theme }) => theme.colors.primary90};
    text-align: center;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.primary30};
    background-color: inherit;
    cursor: pointer;

    ${getFontStyle('p02Medium')}

    &:hover {
        border-color: ${({ theme }) => theme.colors.primary35};
    }

    &:focus-visible {
        color: ${({ theme }) => theme.colors.primary50};
    }
`

export { Training, TrainingHeader, TrainingTitle, TrainingDescription } from './Training/training.styles'

import React from 'react'
import { Skeleton } from '@material-ui/lab'

import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'

import * as S from './../category.styles'

export const CategorySkeleton = () => {
    return (
        <S.Category>
            <S.Header>
                <S.Title>
                    <Skeleton width={120} />
                </S.Title>
            </S.Header>

            <S.List>
                {new Array(4).fill('').map((_, index) => (
                    <CatalogItemSkeleton key={index} />
                ))}
            </S.List>
        </S.Category>
    )
}

import { Queries } from 'api/queries.enum'
import { GetProgressBannersResponse } from 'api/actions/home/progressBanner/progressBannerActions.types'
import { getProgressBanners } from 'api/actions/home/progressBanner/progressBannerActions'
import { useQuery } from 'hooks/useQuery'

export const useGetProgressBanners = () => {
    const query = useQuery<GetProgressBannersResponse, Error>({
        queryKey: [Queries.getProgressBanner],
        queryFn: getProgressBanners,
    })

    return query
}

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetDiversityBreakdownParams, GetDiversityBreakdownResponse } from './diversityBreakdownActions.types'

export const getDiversityBreakdown = async (
    params: GetDiversityBreakdownParams,
): Promise<GetDiversityBreakdownResponse> => {
    const response = await diversioApiV2.get(endpoints.home.diversityBreakdown, { params })

    return response.data
}

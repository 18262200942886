import { AxiosError } from 'axios'

import { Queries } from 'api/queries.enum'
import { GetAnnouncementsResponse } from 'api/actions/home/announcements/announcementsActions.types'
import { getAnnouncements } from 'api/actions/home/announcements/announcementsActions'
import { preventRefetchQueryOptions } from 'utils/queryOptions'
import { useQuery } from 'hooks/useQuery'

export const useGetAnnouncements = () => {
    const query = useQuery<GetAnnouncementsResponse, AxiosError>({
        queryKey: [Queries.getAnnouncements],
        queryFn: getAnnouncements,
        ...preventRefetchQueryOptions,
    })

    return query
}

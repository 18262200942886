import React from 'react'
import { Button, ReloadIcon } from '@diversioteam/diversio-ds'

import { useGetTrainingsCatalogsInfinite } from 'hooks/catalogs/useGetTrainingsCatalogsInfinite'
import { CatalogItemSkeleton } from 'components/Catalog/CatalogItem/skeleton'
import { CatalogItem } from 'components/Catalog/CatalogItem'

import { useTrainings } from './../hooks/useTrainings'
import * as S from './categoryInfinite.styles'

export const CategoryInfinite = () => {
    const { selectedTraining, debouncedSearchValue, selectedKpis, onChangeSelectedTraining, selectedLevel } =
        useTrainings()

    const { data, isLoading, fetchNextPage, isFetchingNextPage } = useGetTrainingsCatalogsInfinite({
        page_size: 12,
        search: debouncedSearchValue,
        kpis: selectedKpis.map(({ value }) => value.toString()),
        ...(selectedLevel !== 'ALL' && { training_level: selectedLevel }),
    })

    const resources = data?.pages

    const hasNextPage = resources && resources[resources.length - 1].next !== null

    const handleClickLoadMore = () => {
        fetchNextPage()
    }

    const renderContent = () => {
        if (resources && resources[0].results.length === 0) {
            return <S.NoResults />
        }

        return (
            <>
                <S.List>
                    {isLoading && new Array(12).fill('').map((_, index) => <CatalogItemSkeleton key={index} />)}

                    {resources &&
                        resources.flatMap(({ results }) =>
                            results.map((props) => (
                                <CatalogItem
                                    key={props.uuid}
                                    {...props}
                                    isSelected={false}
                                    selectedCatalogItem={selectedTraining}
                                    onChangeSelectedCatalogItem={onChangeSelectedTraining}
                                    isTrainingsView
                                />
                            )),
                        )}
                </S.List>

                {hasNextPage && (
                    <S.LoadMore>
                        <Button
                            rightIcon={<ReloadIcon type="line" />}
                            type="button"
                            color="primaryLight"
                            onClick={handleClickLoadMore}
                            loading={isFetchingNextPage}
                        >
                            Load More
                        </Button>
                    </S.LoadMore>
                )}
            </>
        )
    }

    return <S.CategoryWrapper>{renderContent()}</S.CategoryWrapper>
}

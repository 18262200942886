import { stringifyQueryParams } from 'utils'

import { diversioApiV2, diversioApiV2FormData } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    CreateJobAdvertPayload,
    CreateJobAdvertResponse,
    GetJobAdvertDetailResponse,
    UploadJobFileMutationPayload,
    JobAdvertItemResponse,
    DownloadJobAdvertPayload,
} from './lumiActions.types'

export const createJobAdvertAction = async (body: CreateJobAdvertPayload): Promise<CreateJobAdvertResponse> => {
    const response = await diversioApiV2.post(endpoints.lumi.createJobAdvert, body)

    return response.data
}

export const getJobAdvertDetailAction = async (jobId: string): Promise<GetJobAdvertDetailResponse> => {
    const response = await diversioApiV2.get(endpoints.lumi.getJobAdvertDetail(jobId))

    return response.data
}

export const deleteJobAdvert = async (jobId: string) => {
    await diversioApiV2.delete(endpoints.lumi.deleteJobAdvert(jobId))
}

export const createJobItemAction = async (jobId: string): Promise<JobAdvertItemResponse> => {
    const response = await diversioApiV2FormData.post(endpoints.lumi.createJobAdvertItem(jobId))

    return response.data
}

export const getJobItemListAction = async (jobId: string): Promise<JobAdvertItemResponse[]> => {
    const response = await diversioApiV2FormData.get(endpoints.lumi.getJobAdvertItemList(jobId))

    return response.data
}

export const uploadJobFileAction = async ({
    jobId,
    jobFileUUID = '',
    formData,
}: UploadJobFileMutationPayload): Promise<JobAdvertItemResponse> => {
    const response = await diversioApiV2FormData.put(endpoints.lumi.uploadJobFile(jobId, jobFileUUID), formData)

    return response.data
}

export const deleteJobFile = async (jobId: string, jobFileId: string) => {
    await diversioApiV2.delete(endpoints.lumi.deleteJobFile(jobId, jobFileId))
}

export const processJobAdvertAction = async (jobId: string) => {
    await diversioApiV2.get(endpoints.lumi.processJobAdvert(jobId))
}

export const downloadJobAdvertAction = async ({ jobId, params }: DownloadJobAdvertPayload): Promise<void> => {
    const response = await diversioApiV2.get(endpoints.lumi.downloadJobAdvert(jobId), {
        responseType: 'arraybuffer',
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
    const link = document.createElement('a')
    link.href = url

    const filenameRegex = /filename=(.+)$/
    const match = response.headers['content-disposition'].match(filenameRegex)

    link.setAttribute('download', match[1])

    document.body.appendChild(link)

    link.click()
}

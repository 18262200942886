import React, { useCallback, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { Actions } from 'actions'
import Loader from 'components/utils/Loader'

import { AppRoute } from 'routing/AppRoute.enum'
import { Queries } from 'api/queries.enum'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { RootState } from 'reducers/rootState.types'

import { LocalStorageKey, SearchParam } from './auth.types'

export const RedirectAuthenticatedUser = () => {
    const dispatch = useDispatch()

    const { uuid: userUUID } = useSelector((state: RootState) => state.auth)

    const queryClient = useQueryClient()

    const { data: userMetadata } = useUserMetadata()

    const refetchUserMetadata = useCallback(async () => {
        await queryClient.refetchQueries({ queryKey: [Queries.getUserMetadata] })
    }, [])

    useEffect(() => {
        refetchUserMetadata()
    }, [])

    const companyList = userMetadata?.companies

    const searchParams = new URLSearchParams(location.search)
    const toParam = searchParams.get(SearchParam.To) || ''

    if (!queryClient.getQueryData([Queries.getUserMetadata])) {
        return <Loader />
    }

    if (toParam) {
        return <Redirect to={toParam} />
    }

    if (companyList?.length === 0) {
        dispatch(Actions.doSignout())

        return <Redirect to={AppRoute.Login} />
    }

    if (userMetadata?.showIntrosplash) {
        return <Redirect to={AppRoute.Intro} />
    }

    if (companyList?.length === 1) {
        localStorage.removeItem(`${LocalStorageKey.FirstTimeMfaUser}${userUUID}`)
        const permissions = companyList[0].permissions

        if (permissions.ilnDashboardHome) return <Redirect to={generateCompanyURI(AppRoute.IlnDashboardHome)} />
        if (permissions.home) return <Redirect to={generateCompanyURI(AppRoute.Home)} />
        if (permissions.survey) return <Redirect to={generateCompanyURI(AppRoute.Survey)} />
        if (permissions.solutionGoals) return <Redirect to={generateCompanyURI(AppRoute.SolutionGoals)} />

        return <Redirect to={generateCompanyURI(AppRoute.Default)} />
    }

    return <Redirect to={AppRoute.ChooseDashboard} />
}

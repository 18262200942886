import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@diversioteam/diversio-ds'
import { useMediaQuery } from '@material-ui/core'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { breakpoints } from 'styles/theme/mediaQueries'

import { Announcements } from './Announcements'
import { Events } from './Events'
import { Solutions } from './Solutions'
import * as S from './sidePanel.styles'

const SidePanelWithoutEB = () => {
    const matches = useMediaQuery(`(min-width:${breakpoints.md}px)`)

    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {
        if (!matches) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [matches])

    return (
        <S.RightContentPanelWrapper>
            <S.ToggleOpenButton
                size="small"
                icon={isOpen ? <ChevronRightIcon type="bold" /> : <ChevronLeftIcon type="bold" />}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            />

            {isOpen ? (
                <S.Content data-testid="side-panel">
                    <Events />
                    <Solutions />
                    <Announcements />
                </S.Content>
            ) : null}
        </S.RightContentPanelWrapper>
    )
}

export const SidePanel = withErrorBoundary(SidePanelWithoutEB, {})

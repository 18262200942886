import React from 'react'
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@diversioteam/diversio-ds'

import { useTour } from 'hooks/useTour'

import * as S from './tour.styles'

export const Tour = () => {
    const { isTourActive, tourStepIndex, steps, onPreviousStep, onNextStep } = useTour()

    if (!isTourActive) return null

    const { title, content, youtubeId } = steps[tourStepIndex]

    const isFirstStep = tourStepIndex === 0
    const isLastStep = tourStepIndex === steps.length - 1

    const nextButtonText = (() => {
        if (isFirstStep) {
            return 'Start Tour'
        }

        if (isLastStep) {
            return 'Close'
        }

        return `${tourStepIndex} of ${steps.length - 1} | Next`
    })()

    return (
        <S.TourDialog open={isTourActive} hideBackdrop={false}>
            {youtubeId && (
                <S.VideoContainer>
                    <iframe
                        width="450"
                        height="300"
                        frameBorder="0"
                        src={`https://www.youtube.com/embed/${youtubeId}?controls=0&autoplay=1&loop=1&playlist=${youtubeId}&vq=hd720p`}
                        title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                </S.VideoContainer>
            )}

            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>

            <DialogActions>
                {!isFirstStep && (
                    <Button color="secondaryLight" onClick={onPreviousStep}>
                        Back
                    </Button>
                )}

                <Button color="primary" onClick={onNextStep}>
                    {nextButtonText}
                </Button>
            </DialogActions>
        </S.TourDialog>
    )
}

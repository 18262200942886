export enum Queries {
    getCompanyFilters = 'getCompanyFilters',
    getDiversityCompanyProfiles = 'getDiversityCompanyProfiles',
    getDiversityCompanyProfilesOptions = 'getDiversityCompanyProfilesOptions',
    getInclusionCompanyProfiles = 'getInclusionCompanyProfiles',
    getInclusionCompanyProfilesOptions = 'getInclusionCompanyProfilesOptions',
    getFreeTextsFilters = 'getFreeTextsFilters',
    getFreeTexts = 'getFreeTexts',
    getBespokeFreeTexts = 'getBespokeFreeTexts',
    getBlogs = 'getBlogs',
    getPrograms = 'getPrograms',
    getCatalogs = 'getCatalogs',
    getMyCatalogs = 'getMyCatalogs',
    getCatalogsInfinite = 'getCatalogsInfinite',
    getCatalogFilters = 'getCatalogFilters',
    getTools = 'getTools',
    getTrainingsCatalogsInfinite = 'getTrainingsCatalogsInfinite',
    getTrainingCatalogFilters = 'getTrainingCatalogFilters',
    getPeopleSurveyed = 'getPeopleSurveyed',
    getEventReminders = 'getEventReminders',
    getDiversityBreakdownByRole = 'getDiversityBreakdownByRole',
    getInclusionInsights = 'getInclusionInsights',
    getInclusionHeatmap = 'getInclusionHeatmap',
    getDiversityBreakdown = 'getDiversityBreakdown',
    getProgramFilters = 'getProgramFilters',
    getUserMetadata = 'getUserMetadata',
    getPastSurveys = 'getPastSurveys',
    getAnnouncements = 'getAnnouncements',
    getInspirationalQuotes = 'getInspirationalQuotes',
    getProgressBanner = 'getProgressBanner',
    getRiskBanner = 'getRiskBanner',
    getDiversityData = 'getDiversityData',
    getDiversityDataOverTime = 'getDiversityDataOverTime',
    getInclusionData = 'getInclusionData',
    getInclusionDataOverTime = 'getInclusionDataOverTime',
    getInclusionPanel = 'getInclusionPanel',
    getInclusionPanelTrainings = 'getInclusionPanelTrainings',
    getInclusionPanelAllTrainings = 'getInclusionPanelAllTrainings',
    getNps = 'getNps',
    getConfiguration = 'getConfiguration',
    getTooltips = 'getTooltips',
    getNationalBenchmarks = 'getNationalBenchmarks',
    getIndustryBenchmarks = 'getIndustryBenchmarks',
    processJobAdvert = 'processJobAdvert',
    getJobAdvertDetail = 'getJobAdvertDetail',
}

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetAnnouncementsResponse } from './announcementsActions.types'

export const getAnnouncements = async (): Promise<GetAnnouncementsResponse> => {
    const response = await diversioApiV2.get(endpoints.home.announcements)

    return response.data
}

import { loadInclusionDataOverTime } from 'sagas/network'
import { InclusionScoresOverTimeProps } from '@diversioteam/diversio-ds'

import { stringifyQueryParams } from '../utils'
import { Queries } from 'api/queries.enum'
import { useQuery } from 'hooks/useQuery'

export type InclusionDataProps = {
    years?: string
    key?: string
    export_format?: string
}

export const useInclusionScoresOverTime = (params: InclusionDataProps) => {
    return useQuery<InclusionScoresOverTimeProps['data'], Error>({
        queryKey: [Queries.getInclusionDataOverTime, ...Object.values(params)],
        queryFn: () => {
            return loadInclusionDataOverTime(stringifyQueryParams(params))
        },
    })
}

import { getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const StepsList = styled.ol`
    list-style: none;
    padding: 0 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    counter-reset: step-counter;
    text-align: left;
`

export const StepItem = styled.li`
    padding: 24px 0;
    display: grid;
    grid-template-areas:
        'number title'
        'number description';
    grid-template-columns: 20px 1fr;
    grid-column-gap: 18px;
    align-items: center;
    counter-increment: step-counter;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};

    &:last-child {
        border-bottom: none;
    }

    &::before {
        grid-area: number;
        content: counter(step-counter);
        width: 20px;
        height: 20px;
        background-color: #e6e6fa;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.primary90};

        ${getFontStyle('captionBold')};

        line-height: 18px;
    }
`

export const StepTitle = styled.h3`
    grid-area: title;
    margin: 0 0 4px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('p02Medium')}
`

export const StepDescription = styled.p`
    grid-area: description;
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('p02Regular')}
`

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetRiskBannerParams, GetRiskBannerResponse } from './riskBannerActions.types'

export const getRiskBanner = async (params: GetRiskBannerParams): Promise<GetRiskBannerResponse> => {
    const response = await diversioApiV2.get(endpoints.home.riskBanner, { params })

    return response.data
}

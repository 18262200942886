import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetBenchmarksResponse } from './benchmarksActions.types'

export const getNationalBenchmarks = async (): Promise<GetBenchmarksResponse> => {
    const response = await diversioApiV2.get(endpoints.benchmarks.national.list)

    return response.data
}

export const getIndustryBenchmarks = async (): Promise<GetBenchmarksResponse> => {
    const response = await diversioApiV2.get(endpoints.benchmarks.industry.list)

    return response.data
}

export const setPrimaryNationalBenchmark = async (id: string): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.benchmarks.national.setPrimary(id))

    return response.data
}

export const setPrimaryIndustryBenchmark = async (id: string): Promise<void> => {
    const response = await diversioApiV2.post(endpoints.benchmarks.industry.setPrimary(id))

    return response.data
}

import { stringifyQueryParams } from 'utils'

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'
import { downloadFile } from 'api/actions/sharedActions'

import {
    GetBespokeFreeTextsParams,
    GetBespokeFreeTextsResponse,
    GetBespokeFreeTextsSingleQuestionParams,
    GetBespokeFreeTextsSingleQuestionResponse,
    GetFreeTextsFiltersParams,
    GetFreeTextsFiltersResponse,
    GetFreeTextsParams,
    GetFreeTextsResponse,
} from './freeTextsActions.types'

export const getFreeTextsFilters = async (params?: GetFreeTextsFiltersParams): Promise<GetFreeTextsFiltersResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.freeTextsFilters, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getFreeTexts = async (params?: GetFreeTextsParams): Promise<GetFreeTextsResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.freeTexts, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getBespokeFreeTexts = async (params?: GetBespokeFreeTextsParams): Promise<GetBespokeFreeTextsResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.bespokeFreeTexts, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const getBespokeFreeTextsSingleQuestion = async (
    params?: GetBespokeFreeTextsSingleQuestionParams,
): Promise<GetBespokeFreeTextsSingleQuestionResponse> => {
    const response = await diversioApiV2.get(endpoints.analyze.inclusion.bespokeFreeTexts, {
        params,
        paramsSerializer: (params) => stringifyQueryParams(params),
    })

    return response.data
}

export const exportFreeTexts = async (params: GetFreeTextsParams): Promise<void> => {
    await downloadFile(params, endpoints.analyze.inclusion.freeTexts)
}

export const exportBespokeFreeTexts = async (params: GetBespokeFreeTextsParams): Promise<void> => {
    await downloadFile(params, endpoints.analyze.inclusion.bespokeFreeTexts)
}

import React from 'react'

import { useGetCatalogFilters } from 'hooks/catalogs/useGetCatalogFilters'

import { useResources } from './hooks/useResources'
import { CatalogItemSidePanel } from './../CatalogItemSidePanel'
import { Filters } from './Filters'
import { Category } from './Category'
import { CategoryInfinite } from './CategoryInfinite'
import { CategorySkeleton } from './Category/skeleton'
import { CategorySearchResults } from './CategorySearchResults'
import * as S from './resources.styles'

export const Resources = () => {
    const { selectedResource, selectedResourceTypes, debouncedSearchValue, onChangeSelectedResource } = useResources()

    const { data, isLoading } = useGetCatalogFilters()

    const resourceTypesFilters = data?.filters.find(({ name }) => name === 'resource_types')

    const hasSingleResourceSelected = selectedResourceTypes.length === 1
    const hasMultipleResourcesSelected = selectedResourceTypes.length > 1
    const hasSearchPhrase = debouncedSearchValue.length > 0

    const renderContent = () => {
        if (isLoading) {
            return new Array(8).fill('').map((_, index) => <CategorySkeleton key={index} />)
        }

        if (!resourceTypesFilters) {
            return null
        }

        if (hasSingleResourceSelected) {
            const filterOption = resourceTypesFilters.options.find(
                ({ value }) => value === selectedResourceTypes[0]?.value,
            )

            if (!filterOption) {
                return null
            }

            return <CategoryInfinite />
        }

        if (hasSearchPhrase) {
            return <CategorySearchResults />
        }

        if (hasMultipleResourcesSelected) {
            return selectedResourceTypes.map((filterOption) => (
                <Category key={filterOption.value} toolsFilterOption={filterOption} />
            ))
        }

        return resourceTypesFilters.options.map((filterOption) => (
            <Category key={filterOption.value} toolsFilterOption={filterOption} />
        ))
    }

    return (
        <S.ResourcesWrapper>
            <Filters />

            <S.Content>
                <S.Title>Workplace Resources</S.Title>

                <S.NoResultsWrapper>
                    <S.NoResults />
                </S.NoResultsWrapper>

                {renderContent()}
            </S.Content>

            <CatalogItemSidePanel
                selectedCatalogItem={selectedResource}
                onChangeSelectedCatalogItem={onChangeSelectedResource}
            />
        </S.ResourcesWrapper>
    )
}

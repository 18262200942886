import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetPeopleSurveyedParams, GetPeopleSurveyedResponse } from './peopleSurveyedActions.types'

export const getPeopleSurveyed = async (params: GetPeopleSurveyedParams): Promise<GetPeopleSurveyedResponse> => {
    const response = await diversioApiV2.get(endpoints.home.peopleSurveyed, { params })

    return response.data
}

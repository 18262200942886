import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import { GetNpsResponse } from './npsActions.types'

export const getNps = async (): Promise<GetNpsResponse> => {
    const response = await diversioApiV2.get(endpoints.home.nps)

    return response.data
}

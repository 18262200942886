import React from 'react'
import { OrganizationIcon, colors, getKpiColor, isCoreKpi } from '@diversioteam/diversio-ds'
import { DataItemOption } from '@diversioteam/diversio-ds/dist/components/core/CascaderFilters/cascaderFilters.types'
import { RadialBarChartProps } from '@diversioteam/diversio-ds/dist/components/core/RadialBarChart/radialBarChart.types'

import { BenchmarkTransformed } from 'api/actions/home/benchmarks/benchmarksActions.types'
import { Inclusion } from 'api/actions/analyze/companyFilters/companyFiltersActions.types'

import { isDataUnavailable, isDominantCell } from './../heatmap.utils'
import { CellProps } from './../heatmap.types'
import { GetBarChartDataProps, SelectedDataItem } from './panel.types'

const VALUE_SPLIT = '__DDS_CASCADER_SPLIT__'

export const isCellProfile = (cell?: CellProps) => {
    return !!cell?.profileColor
}

export const isCellBespoke = (cell?: CellProps) => {
    return cell?.inclusionSubtitle && cell?.inclusionSubtitle.toLowerCase().indexOf('bespoke') > -1
}

export const getTabType = (selectedTab: string, allTabs?: Inclusion[]) => {
    return allTabs?.find((tab) => tab.uuid === selectedTab)?.type
}

export const isCellCompany = (cell?: CellProps) => {
    return cell?.inclusionTitle === 'Company Score'
}
export const isCellSideColumn = (cell?: CellProps) => {
    return !cell?.current && !cell?.key && !cell?.kpi && !cell?.previous
}

export const isCellIndustry = (
    cell: CellProps,
    primaryIndustryBenchmark: BenchmarkTransformed | undefined,
    secondaryIndustryBenchmark: BenchmarkTransformed | undefined,
) => {
    return (
        cell.inclusionTitle === primaryIndustryBenchmark?.label ||
        cell.inclusionTitle === secondaryIndustryBenchmark?.label
    )
}

export const isSelectedTabIn = (tabs: string[]) => {
    return (selectedTab: string) => {
        return tabs.includes(selectedTab)
    }
}

export const flatCascaderSelectedValues = (state: SelectedDataItem[] | undefined) => {
    if (!state) {
        return undefined
    }

    const options: string[] = []

    const processLevel = (level: SelectedDataItem[] | DataItemOption[], currentPath: string[] = []) => {
        level.forEach((item) => {
            const path = [...currentPath, item.name as string]

            if (item.selectedOptions && Array.isArray(item.selectedOptions)) {
                options.push(...item.selectedOptions.map((option) => [...path, option].join(VALUE_SPLIT)))
            } else if (item.options && typeof item.options === 'object') {
                for (const key in item.selectedOptions) {
                    if (Array.isArray(item.selectedOptions[key])) {
                        options.push([...path, key, ...(item.selectedOptions[key] as string[])].join(VALUE_SPLIT))
                    } else if (typeof item.selectedOptions[key] === 'object') {
                        processLevel([item.selectedOptions[key]] as DataItemOption[], path)
                    }
                }
            }
        })
    }

    processLevel(state)

    return options
}

export const getBarChartData = ({
    selectedCell: cell,
    primaryIndustryBenchmark,
    secondaryIndustryBenchmark,
    selectedIndustry,
}: GetBarChartDataProps) => {
    const data: RadialBarChartProps['data'] = []
    if (typeof cell?.current === 'number') {
        data.push({
            name: 'Score',
            score: isDataUnavailable(cell?.current) ? 0 : Number(cell?.current),
            color:
                cell.kpi?.key && isCoreKpi(cell.kpi?.key)
                    ? getKpiColor(cell.kpi?.key || 'NO_KPI', 'strong')
                    : colors.primary40,
        })
    }

    if (
        !isDataUnavailable(cell.kpi?.dominantGroupScore) &&
        !isCellCompany(cell) &&
        !isCellIndustry(cell, primaryIndustryBenchmark, secondaryIndustryBenchmark) &&
        !isCellBespoke(cell) &&
        !isCellProfile(cell) &&
        !isDominantCell(cell)
    ) {
        data.push({
            name: 'Dominant Group',
            score: Number(cell.kpi?.dominantGroupScore),
            color: colors.primary70,
        })
    }

    if (cell && isCellBespoke(cell) && !isDataUnavailable(cell?.kpi?.companyScore || 'N/A')) {
        data.push({
            name: 'Company Score',
            score: Number(cell?.kpi?.companyScore),
            color: colors.primary30,
        })
    }

    if (
        !isDataUnavailable(cell.kpi?.nonDominantGroupScore) &&
        !isCellCompany(cell) &&
        !isCellIndustry(cell, primaryIndustryBenchmark, secondaryIndustryBenchmark) &&
        !isCellBespoke(cell) &&
        !isCellProfile(cell) &&
        isDominantCell(cell)
    ) {
        data.push({
            name: 'Non-Dom Groups',
            score: Number(cell.kpi?.nonDominantGroupScore),
            color: colors.primary70,
        })
    }

    if (cell?.kpi?.benchmarks) {
        const selectedBenchmark =
            cell.kpi?.benchmarks.filter((benchmark) => benchmark.label === selectedIndustry?.label)[0] ||
            cell.kpi?.benchmarks.filter((benchmark) => benchmark.isPrimary)[0] ||
            cell.kpi?.benchmarks[0]

        if (
            !isCellBespoke(cell) &&
            !isCellProfile(cell) &&
            isSelectedTabIn(['target_groups']) &&
            !isDataUnavailable(selectedBenchmark?.score)
        ) {
            data.push({
                name: 'Benchmark',
                score: Number(selectedBenchmark?.score),
                color: colors.primary35,
                legendIcon: <OrganizationIcon type="line" />,
            })
        }
    }

    if (cell?.previous && !isDataUnavailable(cell.previous)) {
        data.push({
            name: 'Previous Score',
            score: Number(cell.previous),
            color: colors.secondary400,
        })
    }

    return data
}

export const panelTableToSubtitleMapping = {
    bespokeMetrics: 'Bespoke',
    engagementMetrics: 'Engagement',
    InclusionMetrics: 'Inclusion',
}

import styled from 'styled-components'
import { Dialog } from '@diversioteam/diversio-ds'

export const TourDialog = styled(Dialog)`
    .MuiBackdrop-root {
        left: 70px;
    }

    &.MuiDialog-root {
        .MuiDialog-paper {
            width: 684px;
            max-width: 100%;
            padding: 30px 30px 48px;
            text-align: center;
            border-radius: 30px;
        }

        .MuiDialogTitle-root {
            padding: 30px 50px 9px;
        }

        .MuiDialogContent-root {
            padding: 9px 50px 15px;
        }

        .MuiDialogActions-root {
            padding: 15px 0 0;
            justify-content: center;
        }

        button {
            min-width: 176px;
        }
    }
`

export const VideoContainer = styled.div`
    overflow: hidden;
    width: 100%;
    aspect-ratio: 3/2;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.primaryGray2};
    border-radius: 20px;

    iframe {
        width: 300%;
        height: 100%;
        margin-left: -100%;
    }
`

import { diversioApiV2 } from 'api/axios/axiosInstance'
import { endpoints } from 'api/endpoints'

import {
    GetDiversityBreakdownByRoleParams,
    GetDiversityBreakdownByRoleResponse,
} from './diversityBreakdownByRoleActions.types'

export const getDiversityBreakdownByRole = async (
    params: GetDiversityBreakdownByRoleParams,
): Promise<GetDiversityBreakdownByRoleResponse> => {
    const response = await diversioApiV2.get(endpoints.home.diversityBreakdownByRole, { params })

    return response.data
}
